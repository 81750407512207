import React from "react";

export default function RequiredStar({ top = 4, left = 4, isRed = false }) {
  return (
    <div
      style={{
        position: "absolute",
        top: top,
        left: left,
        color: isRed ? "red" : "#A3A2D8",
      }}
    >
      *
    </div>
  );
}
