import React, { useEffect, useState } from "react";

import MapPicker from "react-google-map-picker";

const DefaultZoom = 10;

const LocationSelector = ({ onLocationChange, onClose, DefaultLocation }) => {
  // const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(DefaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  useEffect(() => {
    if (location !== DefaultLocation) {
      onLocationChange(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="mobile_tablet:grid mobile_tablet:place-items-center">
      {/* <button onClick={handleResetLocation}>Reset Location</button>
      <label>Latitute:</label>
      <input type="text" value={location.lat} disabled />
      <label>Longitute:</label>
      <input type="text" value={location.lng} disabled />
    <input type="text" value={zoom} disabled /> */}
      <div className="flex justify-between mobile_tablet:flex-col items-center mb-4 ">
        <div className="flex mobile_tablet:flex-col">
          Seleziona Località dell'attività:
        </div>
        <button
          className="bg-customBlue text-white px-4 mobile_tablet:w-full mobile_tablet:mx-8 
        rounded-xl py-2"
          onClick={() => {
            onLocationChange(location);
            onClose();
          }}
        >
          Conferma
        </button>
      </div>

      <MapPicker
        defaultLocation={{
          lat: DefaultLocation?._lat || DefaultLocation.lat,
          lng: DefaultLocation?._long || DefaultLocation.lng,
        }}
        zoom={zoom}
        mapTypeId="roadmap"
        // style={{ height: "700px" }}

        className="rounded-xl mobile_tablet:max-w-[350px] "
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey="AIzaSyD4PiT_luqaFMUUFPK7KeX-BFIHtG4NqMA"
        options={{ streetViewControl: false }}
      />
    </div>
  );
};

export default LocationSelector;
