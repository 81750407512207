import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import axios from "axios";
import TickInCircleICON from "../images/tick-icon.svg";
import bgPosterIMG from "../images/bg-poster.svg";
import bgPosterIMG_DESKTOP from "../images/bg-poster.png";
import bgAccess from "../images/access-bg.svg";

import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const stripePromise = loadStripe(
  "pk_live_51KnP5NLedo04Gny6Eq2phx2aETiBVOyp7Lib66gsEVERL9Y7GpnOxESBlJO1M4CaGvR1EOCiEbPyGzGFa9n4ngSm00k50O2mgr"
);

const CheckoutForm = ({ setPaid }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const onPaymentSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (stripe && elements) {
      try {
        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.href,
          },
          redirect: "if_required",
        });

        if (result.error) setErr(result.error.message);
        else {
          let licenseTimestamp = new Date(
            window.customer.licenseTimestamp.seconds * 1000
          );
          licenseTimestamp = new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          );

          await updateDoc(doc(db, "Customers", window.customerId), {
            licenseType: 1,
            licenseTimestamp: licenseTimestamp,
          });
          setPaid(true);
        }

        return result;
      } catch (err) {
        setErr(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={onPaymentSubmit}>
      <PaymentElement />
      <br />
      <b>
        Totale: <span className="text-[#1967FF] ml-4">100€ + 22€ (IVA)</span>
      </b>
      <br />
      <br />
      {err && <div className="text-red-500 font-bold my-2">{err}</div>}
      <button
        className="w-full bg-[#1967FF] rounded-xl py-4 text-lg text-white tablet:absolute mobile:absolute bottom-[50px] tablet:w-[88%] mobile:w-[92%] disabled:opacity-[0.5]"
        disabled={loading}
      >
        {loading ? "loading..." : "Paga"}
      </button>
    </form>
  );
};

const PaymentPage = ({ refresh }) => {
  const [stripeOptions, setStripeOptions] = useState();
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [paid, setPaid] = useState(false);

  const navigate = useNavigate();

  const getPaymentIntent = async () => {
    setPaymentLoading(true);
    const { data } = await axios.post(
      "https://infoischia-server.vercel.app/fetch_client_secret",
      {
        amount: 122 * 100,
        description: `${window.customer.firstName} ${window.customer.lastName}, ${window.customer.shopName} - ${window.customer.shopCategory}`,
      }
    );

    setStripeOptions({
      clientSecret: data.paymentIntent.client_secret,
      appearance: {
        theme: "minimal",
        labels: "floating",
      },
    });
    setPaymentLoading(false);
  };

  useEffect(() => {
    getPaymentIntent();
    refresh();
    return refresh;
  }, []);

  if (window?.customer?.licenseType !== 0) {
    navigate("/dashboard");
    return <>Not Authenticated, Redirecting..</>;
  }

  return (
    <div className="w-screen h-screen">
      <TopBar paid={paid} />
      {!paid ? (
        <>
          <div className="flex justify-between pt-[6vh] tablet:pt-[8vh] mobile:pt-[8vh] tablet:flex-col-reverse mobile:flex-col-reverse mobile:p-4 tablet:p-8 z-[0]">
            <div className="w-1/2 tablet:w-full mobile:w-full p-8 tablet:p-0 mobile:p-0 pt-16 pl-16">
              {!paymentLoading && (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <CheckoutForm setPaid={setPaid} />
                </Elements>
              )}
            </div>
            <div className="w-1/2 tablet:w-full mobile:w-full p-8 tablet:p-0 mobile:p-0 pt-16 pl-16">
              <h1 className="text-4xl laptop:text-2xl tablet:text-2xl mobile:text-lg text-[#1967FF] font-extrabold flex gap-2 items-center tablet:justify-center mobile:justify-center">
                Entra a far parte di{" "}
                <div className="flex gap-1 items-end">
                  <div className="flex">
                    <span className="text-pink-500">Info</span>
                    <span className="text-[#1967FF]">Ischia</span>
                  </div>
                  <span className="text-pink-500 text-sm mb-1">Pro</span>
                </div>
              </h1>
              <br />
              <div className="grid grid-cols-2 text-base laptop:text-sm grid-rows-4 text-[#1967FF] tablet:hidden mobile:hidden">
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Vetrina con nome del locale, Immagini
                </div>
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Recapiti telefonici più link ai social
                </div>
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Luogo e indicazioni per gli utenti
                </div>
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Link esterni al sito o menu etc…
                </div>
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Gestionale Pro, con statistichesu tempo medio
                  Visualizzazioniclick e condivisioni
                </div>
                <div className="flex gap-2 items-start">
                  <TickIcon />
                  Possibilità di pubblicare 1 evento al mese
                </div>
              </div>
            </div>
          </div>
          <img
            src={bgPosterIMG}
            alt="bgPosterIMG"
            className={`absolute bottom-[50px] right-[60%] translate-x-[50%] scale-100 mobile:scale-150 pointer-events-none z-[-1] desktop:hidden laptop:hidden`}
          />
          <img
            src={bgPosterIMG_DESKTOP}
            alt="bgPosterIMG_DESKTOP"
            className="absolute bottom-0 right-0 pointer-events-none z-[-1] tablet:hidden mobile:hidden"
          />
        </>
      ) : (
        <>
          <img
            src={bgAccess}
            alt="bgAccess"
            className="absolute top-0 bottom-0 left-0 w-screen h-screen pointer-events-none z-[0] tablet:scale-[2] mobile:scale-[4]"
          />
          <div className="flex flex-col items-center justify-between h-screen py-[10vh] z-[9999]">
            <h1 className="flex-col justify-center items-center">
              <div className="text-4xl tablet:text-xl mobile:text-xl text-[#1967FF] font-semibold flex gap-2 items-center tablet:justify-center mobile:justify-center">
                Entra a far parte di{" "}
                <div className="flex gap-1 items-end">
                  <div className="flex">
                    <span className="text-pink-500">Info</span>
                    <span className="text-[#1967FF]">Ischia</span>
                  </div>
                  <span className="text-pink-500 text-sm mb-1">Pro</span>
                </div>
              </div>
              <h1 className="text-xl tablet:text-sm mobile:text-sm text-center text-[#1967FF] font-semibold">
                Grazie per aver acquistato la licenza Pro
              </h1>
            </h1>
            <button
              className="px-[100px] tablet:px-[60px] mobile:px-[60px] mobile:text-base font-bold py-4 bg-[#1967FF] text-white rounded-xl text-xl z-[9999]"
              onClick={async () => {
                const docRef = await getDoc(
                  doc(db, "Customers", window.customerId)
                );
                window.customer = docRef.data();
                localStorage.setItem("customer", JSON.stringify(docRef.data()));
                window.location.replace("/dashboard");
              }}
            >
              Accedi a InfoIschia Pro
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const TickIcon = ({ size = 20 }) => (
  <img
    src={TickInCircleICON}
    alt="TickInCircleICON"
    width={size}
    height={size}
  />
);

const TopBar = ({ paid }) => {
  const navigate = useNavigate();
  return (
    <div className="absolute top-0 w-screen h-[6vh] flex justify-between px-8 py-4">
      <div
        className="text-[#1967FF] font-bold text-4xl mobile:text-2xl tablet:text-2xl cursor-pointer"
        onClick={() => navigate("/dashboard")}
      >
        {!paid && <>&#10005;</>}
      </div>
      <div className="flex gap-1 font-semibold text-3xl tablet:text-2xl mobile:text-2xl">
        <div className="flex ">
          <span className="text-pink-500">Info</span>
          <span className="text-[#1967FF]">Ischia</span>
        </div>
        <span className="text-pink-500">Pro</span>
      </div>
      <div></div>
    </div>
  );
};

export default PaymentPage;
