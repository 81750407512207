import React from "react";
import Component1 from "../components/component-1";
import Component2 from "../components/component2/component2";
import Component3 from "../components/component3/component3";
import Footer from "../components/footer/index";
import { Helmet } from "react-helmet";

function ProHomePage({ setShowSideBar }) {
  return (
    <div>
      <Helmet>
        <title>Infoischia - Pro</title>
        <link rel="stylesheet" href="/landing.css" />
      </Helmet>
      <Component1 />
      <Component2 />
      <Component3 setShowSideBar={setShowSideBar} />
      <Footer />
    </div>
  );
}

export default ProHomePage;
