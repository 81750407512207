import React from "react";
import SignIn from "../components/singin/signin";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

function SignInPage({ setShowSideBar }) {
  return (
    <div className=" bg-white">
      <Helmet>
        <title>Infoischia | Sign In</title>
        <link rel="stylesheet" href="/landing.css" />
      </Helmet>
      <SignIn setShowSideBar={setShowSideBar} />
      <Footer />
    </div>
  );
}

export default SignInPage;
