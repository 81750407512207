import React, { useEffect, useRef, useState } from "react";
import Logo from "../../images/logo.png";
import CloseIcon from "../../images/closeBlue.png";
import ExitIcon from "../../images/exitIcon.png";
import HomeIcon from "../../images/homeBlue2.png";
import HomeIconFaded from "../../images/homeBlueFaded.png";
import LockIcon from "../../images/lock.png";
import OnlineIcon from "../../images/onlineBlue2.png";
import OnlineIconFaded from "../../images/onlineBlueFaded.png";
import CheckIcon from "../../images/checkBlue2.png";
import CheckIconFaded from "../../images/checkBlueFaded.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

const Sidebar = ({
  toggleSidebar,
  loading,
  selected,
  setSelected,
  customer,
}) => {
  const sidebarRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // const [window?.customer?.licenseType, setwindow?.customer?.licenseType] = useState(window.customer.window?.customer?.licenseType);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setSelected(1);
    } else if (location.pathname === "/vetrina") {
      setSelected(2);
    } else if (location.pathname === "/eventi") {
      setSelected(3);
    } else if (location.pathname === "/eventi/create") {
      setSelected(3);
    }
  }, [location.pathname]);

  const handleRoute = (route) => {
    navigate(route);
  };

  if (window.location.pathname === "/payment" || loading === true) return <></>;
  return (
    <div
      className="bg-white z-10 flex flex-col fixed md:absolute top-0 left-0 h-full overflow-hidden md:top-[53px] md:left-[58px] md:rounded-[44px] md:shadow-2xl md:h-[770px]"
      ref={sidebarRef}
    >
      <img
        src={CloseIcon}
        alt="cross"
        className="mb-[11px] mt-[22px] ml-[20px] h-[26px] w-[26px] object-cover md:hidden"
        onClick={() => toggleSidebar()}
      />

      <div className="flex flex-col items-center px-[34px] mb-[27px]">
        <img src={Logo} alt="Logo" className="md:mt-[10px]" />
        <span className="font-poppins font-bold text-[15px] leading-[22px] text-customPink">
          Info<span className="text-customBlue">Ischia </span>
          <span className="text-[6px] leading-[9px]">Pro</span>
        </span>
      </div>

      <div className="flex-grow flex flex-col space-y-[18px] pl-[16px] pr-[10px]">
        <div
          className={`${
            selected === 1 ? "bg-[#F2F2FE]" : "bg-white"
          } flex items-center space-x-[8px] py-[5px] pl-[8px] pr-[12px] hover:cursor-pointer hover:bg-[#F2F2FE]`}
          onClick={() => {
            setSelected(1);
            handleRoute("/dashboard");
          }}
        >
          <img
            src={selected === 1 ? HomeIcon : HomeIconFaded}
            alt="home"
            className={`w-[24px] h-[23px] object-contain`}
          />
          <span
            className={`${
              selected === 1 ? "opacity-[1]" : "opacity-60"
            } font-poppins font-semibold text-customBlue text-[14px] leading-[21px]"`}
          >
            Dashboard
          </span>
        </div>

        <div
          className={`${
            selected === 2 ? "bg-[#F2F2FE]" : "bg-white"
          } flex items-center space-x-[8px] py-[5px] pl-[8px] pr-[12px] hover:cursor-pointer hover:bg-[#F2F2FE]`}
          onClick={() => {
            if (window?.customer?.licenseType === -1) return;
            setSelected(2);
            handleRoute("/vetrina");
          }}
        >
          <img
            src={selected === 2 ? OnlineIcon : OnlineIconFaded}
            alt="home"
            className={`w-[24px] h-[23px] object-contain`}
          />
          <span
            className={`${
              selected === 2 ? "opacity-[1]" : "opacity-60"
            } font-poppins font-semibold text-customBlue text-[14px] leading-[21px]"`}
          >
            Vetrina
          </span>
          {window?.customer?.licenseType === -1 && (
            <img
              src={LockIcon}
              alt="lock"
              className={`${selected === 2 ? "" : "opacity-40"}`}
              width="15"
            />
          )}
        </div>

        <div
          className={`${
            selected === 3 ? "bg-[#F2F2FE]" : "bg-white"
          } flex items-center space-x-[8px] py-[5px] pl-[8px] pr-[12px] hover:cursor-pointer hover:bg-[#F2F2FE]`}
          onClick={() => {
            if (window?.customer?.licenseType === -1) return;
            if (window?.customer?.licenseType === 0) return;
            setSelected(3);
            handleRoute("/eventi");
          }}
        >
          <img
            src={selected === 3 ? CheckIcon : CheckIconFaded}
            alt="home"
            className={`w-[24px] h-[23px] object-contain`}
          />
          <span
            className={`${
              selected === 3 ? "opacity-[1]" : "opacity-60"
            } font-poppins font-semibold text-customBlue text-[14px] leading-[21px]"`}
          >
            Eventi
          </span>
          {(window?.customer?.licenseType === -1 ||
            window?.customer?.licenseType === 0) && (
            <img
              src={LockIcon}
              alt="lock"
              className={`${selected === 2 ? "" : "opacity-40"}`}
              width="15"
            />
          )}
        </div>
      </div>

      <div className="flex items-center gap-4 flex-col justify-center mb-[13px]">
        <div className="flex flex-col items-center mb-6 justify-center mobile:hidden tablet:hidden">
          <h6 className="text-lightPurple">Password</h6>
          <h1 className="text-lightPurple font-bold">
            {customer?.password || ""}
          </h1>
        </div>
        <div
          onClick={() => {
            delete window.customerId;
            delete window.customer;
            localStorage.removeItem("customer");
            localStorage.removeItem("customerId");
            sessionStorage.removeItem("customer");
            sessionStorage.removeItem("customerId");
            window.scroll(0, 0);
            navigate("/signin");
            toggleSidebar();
          }}
          className="flex items-center gap-2  cursor-pointer"
        >
          <img
            src={ExitIcon}
            alt="exit"
            className="h-[17px] w-[17px] object-contain "
          />
          <span className="font-poppins font-semibold text-gray3 text-[18px] leading-[27px] ">
            Exit
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
