import React from "react";
import { Col, Row } from "antd";
import Circle from "../../images/gol.png";
import Mobile from "../../images/Raggruppa 609 1.svg";
import Icon from "../../images/reserve.png";
import Icon1 from "../../images/more-square.png";
import Icon2 from "../../images/location.png";
import Icon3 from "../../images/heart.png";
import Logo from "../../images/arr.png";
import Icon4 from "../../images/Raggruppa 467.png";
import Icon5 from "../../images/Raggruppa 468.png";
import Icon6 from "../../images/Raggruppa 455.png";
import "./style.css";
import { Link } from "react-router-dom";
function CompoTwo() {
  return (
    <>
      <div className="doneimg">
        <img className="img-fluid imgdone" src={Icon6} alt="" />
      </div>
      <div className="maindiv">
        <Row justify="space-evenly" className="flex-column-reverse flex-sm-row">
          <Col lg={10} md={20} xs={24}>
            <div className="centercompotwo mt-16">
              <img className="img-fluid circleimg" src={Circle} alt="" />
              <img
                className="img-fluid moblieimg"
                src={Mobile}
                alt=""
                // style={{ boxShadow: "0px 0px 100px -50px #161616" }}
              />
            </div>
          </Col>
          <Col lg={12} md={20} xs={24}>
            <div className="centercompotwo22">
              <div className="maindivscop">
                <div className="scopdiv00 flex flex-col gap-2 ">
                  <div className="divmigsub22 flex items-center gap-4 my-2">
                    <img
                      className="img-fluid imgimg2 imgimg33"
                      src={Icon}
                      alt=""
                    />
                    <p className="textmig ">
                      Scopri i migliori ristoranti di Ischia
                    </p>
                  </div>
                  <p className="textmig198 mb-2">
                    Scorri tra i ristoranti, filtrali per tipo di cucina
                    <br /> per prezzo o trova quelli più vicini a te!
                  </p>
                </div>
                <div className="scopdiv  flex flex-col gap-2 ">
                  <div className="divmigsub22 flex items-center gap-4 my-2">
                    <img
                      className="img-fluid imgimg2 imgimg33"
                      src={Icon1}
                      alt=""
                    />
                    <p className="textmig">
                      Scopri le attrazioni più interessanti!
                    </p>
                  </div>
                  <p className="textmig198 mb-2">
                    Trova le attrazioni interessanti, prenota una
                    <br /> visita e scopri molto di più
                  </p>
                </div>
              </div>
              <div className="maindivscop">
                <div className="scopdiv22  flex flex-col gap-2 ">
                  <div className="divmigsub flex items-center gap-4 my-2">
                    <img className="img-fluid imgimg24" src={Icon2} alt="" />
                    <p className="textmig">Scopri la Mappa! </p>
                  </div>
                  <p className="textmig1 marginleftok mb-2">
                    Trova sulla mappa tutti i posti più interessanti
                    <br /> Un’isola tutta da scoprire!
                  </p>
                </div>
                <div className="scopdiv22  flex flex-col gap-2 ">
                  <div className="divmigsub flex items-center gap-4 my-2">
                    <img className="img-fluid imgimg24" src={Icon3} alt="" />
                    <p className="textmig">Salva i tuoi posti preferiti</p>
                  </div>
                  <p className="textmig1 marginleftok mb-2">
                    Aggiungi ai Preferiti i posti che ti sono piaciuti
                    <br /> di più.
                  </p>
                </div>
              </div>
              <Link
                style={{ textDecoration: "none" }}
                to="/pro"
                onClick={() => window.scrollY(0)}
              >
                <button className="btnduiv">
                  <div>
                    <p className="textloc ml-3">Hai un’attività?</p>
                    <p className="textloc1">
                      Iscrivila su <a className="textloc233">Info</a>Ischia
                    </p>
                  </div>
                  <img className="mt-[-35px]" src={Logo} alt="" />
                </button>
              </Link>
              <div
                // class-Name=""
                className="maindivseg flex justify-between mobile:mt-[-50px]"
              >
                {/* <button className="divseg"> */}
                <a
                  style={{ textDecoration: "none" }}
                  className="divseg"
                  href="https://www.instagram.com/infoischia/?igshid=YmMyMTA2M2Y%3D"
                >
                  <img className="img-fluid imgimg" src={Icon4} alt="" />
                  <p className="textseg">Seguici su Instagram!</p>
                </a>
                {/* </button>
                <button className="divseg"> */}
                <a
                  style={{ textDecoration: "none" }}
                  className="divseg"
                  href="https://www.facebook.com/infoischia"
                >
                  <img className="img-fluid imgimg" src={Icon5} alt="" />
                  <p className="textseg">Seguici su Facebook!</p>
                </a>
                {/* </button> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CompoTwo;
