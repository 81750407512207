import React, { useEffect, useState, useRef } from "react";
import DragZone from "../../components/DragZone/DragZone";
import NameFieldIcon from "../../images/nameFieldIcon.png";
import DescriptionFieldIcon from "../../images/textDashedGray.png";
import ClockIcon from "../../images/clockGray.png";
import PhoneIcon from "../../images/phoneGray.png";
import ApplicationIcon1 from "../../images/application1Gray.png";
import ApplicationIcon2 from "../../images/application2Gray.png";
import TextIcon from "../../images/textIcon.png";
import PrincipalFieldIcon from "../../images/principalFieldIcon.png";
import InstagramIcon from "../../images/instagramGray.png";
import FacebookIcon from "../../images/facebookGray.png";
import EmailIcon from "../../images/email.svg";
import LoadingIcon from "../../components/LoadingIcon";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
  increment,
  GeoPoint,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useNavigate } from "react-router-dom";
import RequiredStar from "../../components/RequiredStar";
import LocationSelector from "../../components/LocationSelector";
import LocationIcon from "../../images/locationGray.png";

const numberInWordMonths = [
  "firstMonth",
  "secondMonth",
  "thirdMonth",
  "fourthMonth",
  "fifthMonth",
  "sixthMonth",
  "seventhMonth",
  "eighthMonth",
  "ninthMonth",
  "tenthMonth",
  "eleventhMonth",
  "twelvethMonth",
];

const mainButtonActionType = [
  "Prenota ora",
  "Chiama ora",
  "Seguici su Instagram",
  "Seguici su Facebook",
  "Connessione al primo link principale",
  "Connessione al secondo link principale",
  "Scrivici una mail",
];

export default function Create({ loading, setLoading }) {
  const [images, setImages] = useState([]);
  let [formData, setFormData] = useState({
    email: { en: "", it: "" },
    eventDescription: { en: "", it: "" },
    facebookLink: { en: "", it: "" },
    firstLink: { en: "", it: "" },
    firstLinkName: { en: "", it: "" },
    firstLinkDescription: { en: "", it: "" },
    instagramLink: { en: "", it: "" },
    mainButtonActionType: { en: "", it: "" },
    mainButtonText: { en: "", it: "" },
    phoneNumber: { en: "0", it: "0" },
    secondLink: { en: "", it: "" },
    secondLinkDescription: { en: "", it: "" },
    secondLinkName: { en: "", it: "" },
    eventName: { en: "", it: "" },

    address: { en: "", it: "" },
    emailToReceive: { en: "", it: "" },
    shopTag: "",
    eventEndTimestamp: "",
    eventStartTimestamp: "",
    startDate: "",
    endDate: "",
    imageUrls: { it: [], en: [] },
    addressGeopoint: new GeoPoint(40.72846578226386, 13.902980132948867),
  });
  const navigate = useNavigate();
  const mainButtonTextField = useRef();
  const mainButtonActionTypeRef = useRef();

  const [openLocationSelector, setOpenLocationSelector] = useState(false);
  const [location, setLocation] = useState({
    lat: 40.72846578226386,
    lng: 13.902980132948867,
  });

  const [locale, setLocale] = useState("it");
  const [refreshVal, setRefresh] = useState(0);
  const [formLoading, setFormLoading] = useState(false);

  const refresh = () => setRefresh(Math.random());

  const [formErrors, setFormErrors] = useState({
    shopNameError: "",
    descriptionError: "",
    phoneNumberError: "",
    general: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [newButtonText, setNewButtonText] = useState("");

  const getCustomer = async () => {
    try {
      const document = await getDoc(doc(db, "Customers", window.customerId));
      if (document.exists()) window.customer = document.data();
      else throw new Error("No Customer found with the matching id.");
    } catch (err) {
      console.error("Error: " + err.message);
      // setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (
      !["startTime", "endTime", "startDate", "endDate"].includes(e.target.name)
    ) {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          [locale]: e.target.value,
        },
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    refresh();
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length !== 0) {
      console.log(errors);
    } else {
      setFormLoading(true);

      const licenseTimestamp = new Date(
        window.customer.licenseTimestamp.seconds * 1000
      );
      const timestamp = new Date(
        licenseTimestamp.setFullYear(licenseTimestamp.getFullYear() - 1)
      );
      const currentTimestamp = new Date();

      const calculatedMonthName =
        numberInWordMonths[
          Math.abs(timestamp.getMonth() - currentTimestamp.getMonth())
        ];

      if (window.customer.freeEvent[calculatedMonthName] === true) {
        await updateDoc(
          doc(db, "Customers", window.customerId),
          {
            eventRequestMap: {
              ...window.customer.eventRequestMap,
              eventMinus: Math.abs(
                timestamp.getMonth() - currentTimestamp.getMonth()
              ),
            },
            freeEvent: {
              ...window.customer.freeEvent,
              [calculatedMonthName]: false,
            },
          },
          { merge: true }
        );

        await getCustomer().then(() => {
          setIsSubmit(true);
          refresh();
        });
      } else if (window.customer.freeEvent[calculatedMonthName] === false) {
        if (window.customer.eventNum === 0) {
          setNewButtonText("Eventi finiti, invia mail per acquistarne altri");
          setFormLoading(false);
        } else if (window.customer.eventNum > 0) {
          await updateDoc(doc(db, "Customers", window.customerId), {
            eventNum: increment(-1),
            eventRequestMap: {
              ...window.customer.eventRequestMap,
              eventMinus: -1,
            },
          })
            .then(getCustomer)
            .then(() => {
              setIsSubmit(true);
              refresh();
            });
        }
      }
    }
  };

  const validateForm = () => {
    const error = {};

    if (formData.eventName.en.length === 0) {
      error.shopNameError = "Cannot be empty (Inglese)";
      return error;
    }
    if (formData.eventName.it.length === 0) {
      error.shopNameError = "Cannot be empty (Italiano)";
      return error;
    }

    if (formData.eventDescription.length <= 0) {
      error.descriptionError = "Cannot be empty";
      return error;
    }

    if (
      formData.phoneNumber.en.length <= 0 ||
      formData.phoneNumber.en === "0"
    ) {
      error.phoneNumberError = "Cannot be empty (Inglese)";
      return error;
    }
    if (
      formData.phoneNumber.it.length <= 0 ||
      formData.phoneNumber.it === "0"
    ) {
      error.phoneNumberError = "Cannot be empty (Italiano)";
      return error;
    }

    if (formData.mainButtonText.it === "") {
      error.general = "No main button text (Italiano)";
      return error;
    }
    if (formData.mainButtonText.en === "") {
      error.general = "No main button text (Inglese)";
      return error;
    }

    if (formData.mainButtonActionType.it === "") {
      error.general = "No main button action type (Italiano)";
      return error;
    }
    if (formData.mainButtonActionType.en === "") {
      error.general = "No main button action type (Inglese)";
      return error;
    }

    if (!formData.startDate) {
      error.general = "Invalid Dates";
      return error;
    }
    if (!formData.endDate) {
      error.general = "Invalid Dates";
      return error;
    }

    if (window.customer.shopCategory === "Culture") {
      if (
        location.lat === 40.72846578226386 ||
        Math.round(location.lat) === 0
      ) {
        error.general = "Select location on the map.";
        return error;
      }
    }

    if (
      formData.firstLink.it ||
      formData.firstLinkName.it ||
      formData.firstLinkDescription.it ||
      formData.mainButtonActionType.it === "4"
    ) {
      if (!formData.firstLink.it) {
        error.general = "No first link (Italiano)";
        return error;
      }
      if (!formData.firstLinkName.it) {
        error.general = "No first link name (Italiano)";
        return error;
      }
      if (!formData.firstLinkDescription.it) {
        error.general = "No first link description (Italiano)";
        return error;
      }
    }

    if (
      formData.secondLink.it ||
      formData.secondLinkName.it ||
      formData.secondLinkDescription.it ||
      formData.mainButtonActionType.it === "5"
    ) {
      if (!formData.secondLink.it) {
        error.general = "No second link (Italiano)";
        return error;
      }
      if (!formData.secondLinkName.it) {
        error.general = "No second link name (Italiano)";
        return error;
      }
      if (!formData.secondLinkDescription.it) {
        error.general = "No second link description (Italiano)";
        return error;
      }
    }

    if (
      formData.firstLink.en ||
      formData.firstLinkName.en ||
      formData.firstLinkDescription.en ||
      formData.mainButtonActionType.en === "4"
    ) {
      if (!formData.firstLink.en) {
        error.general = "No first link (Inglese)";
        return error;
      }
      if (!formData.firstLinkName.en) {
        error.general = "No first link name (Inglese)";
        return error;
      }
      if (!formData.firstLinkDescription.en) {
        error.general = "No first link description (Inglese)";
        return error;
      }
    }

    if (
      formData.secondLink.en ||
      formData.secondLinkName.en ||
      formData.secondLinkDescription.en ||
      formData.mainButtonActionType.en === "5"
    ) {
      if (!formData.secondLink.en) {
        error.general = "No second link (Inglese)";
        return error;
      }
      if (!formData.secondLinkName.en) {
        error.general = "No second link name (Inglese)";
        return error;
      }
      if (!formData.secondLinkDescription.en) {
        error.general = "No second link description (Inglese)";
        return error;
      }
    }

    if (formData.shopTag === "") {
      error.general = "Add some shop tags.";
      return error;
    }

    if (new Date(formData.startDate) >= new Date(formData.endDate)) {
      // console.log(
      //   String(String(formData.startDate).split("T")[1]).split(":")[0] <
      //     String(String(formData.endDate).split("T")[1]).split(":")[0]
      // );
      error.general =
        "La data d'inizio dell'evento deve essere prima di quella finale";
      return error;
    }

    if (formData.imageUrls.en.length === 0) {
      error.general = "Add Images (Inglese)";
      return error;
    }
    if (formData.imageUrls.it.length === 0) {
      error.general = "Add Images (Italiano)";
      return error;
    }
    return error;
  };

  const uploadData = async () => {
    setFormLoading(true);
    let index = 0;
    let urls = [];

    let data = {
      eventRequest: 0,
      eventRequestMap: {
        ...window.customer.eventRequestMap,
        email: formData.email,
        shopTag: formData.shopTag,

        eventDescription: {
          it: formData.eventDescription.it.replace(/(?:\r\n|\r|\n)/g, "///n"),
          en: formData.eventDescription.en.replace(/(?:\r\n|\r|\n)/g, "///n"),
        },
        facebookLink: formData.facebookLink,
        firstLink: formData.firstLink,
        firstLinkDescription: formData.firstLinkDescription,
        firstLinkName: formData.firstLinkName,
        instagramLink: formData.instagramLink,
        mainButtonActionType: {
          it: Number(formData.mainButtonActionType.it),
          en: Number(formData.mainButtonActionType.en),
        },
        mainButtonText: formData.mainButtonText,
        phoneNumber: formData.phoneNumber,
        secondLink: formData.secondLink,
        secondLinkDescription: formData.secondLinkDescription,
        secondLinkName: formData.secondLinkName,
        eventName: formData.eventName,
        lastSentTimestamp: new Date(),
        imageUrls: {},
        eventStartTimestamp: new Date(formData.startDate),
        eventEndTimestamp: new Date(formData.endDate),
      },
    };

    if (window.customer.shopCategory === "Culture") {
      data.eventRequestMap.address = formData.address;
      data.eventRequestMap.addressGeopoint = new GeoPoint(
        location.lat,
        location.lng
      );
    }

    data.eventRequestMap.imageUrls.en = formData.imageUrls.en.map(
      async (image) => {
        if (typeof image === "string") return image;
        return await uploadImages(image);
      }
    );
    data.eventRequestMap.imageUrls.it = formData.imageUrls.it.map(
      async (image) => {
        if (typeof image === "string") return image;
        return await uploadImages(image);
      }
    );

    const enImages = await Promise.all(data.eventRequestMap.imageUrls.en);
    const itImages = await Promise.all(data.eventRequestMap.imageUrls.it);

    data.eventRequestMap.imageUrls.en = enImages;
    data.eventRequestMap.imageUrls.it = itImages;

    await updateDoc(doc(db, "Customers", window.customerId), data, {
      merge: true,
    }).catch((err) => {
      console.error(err);
    });

    await getCustomer();
    navigate("/eventi");
    setFormLoading(false);
  };

  const uploadImages = async (image) => {
    // if (images.length > 0) {
    if (typeof image === "string") return image;
    const file = image;

    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substring(i, 2)).toString(36);
    }
    const fileName = "customerImage" + out + "-" + Math.random();
    const storageRef = ref(storage, "customerImages/" + fileName);
    const fileSnapshot = await uploadBytes(storageRef, file);
    console.log("Image Uploaded!");
    const downloadURL = await getDownloadURL(fileSnapshot.ref);
    return downloadURL;
  };

  useEffect(() => {
    (async () => {
      const localCustomerId =
        localStorage.getItem("customerId") ||
        sessionStorage.getItem("customerId");
      const localCustomer =
        localStorage.getItem("customer") || sessionStorage.getItem("customer");

      if (localCustomerId !== null) window.customerId = localCustomerId;
      if (localCustomer !== null) window.customer = JSON.parse(localCustomer);

      if (!window.customerId) window.location.replace("/signIn");
      if (!window.customer) await getCustomer();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (mainButtonActionTypeRef.current)
      if (formData.mainButtonActionType[locale] !== "") {
        mainButtonActionTypeRef.current.selectedIndex =
          Number(formData.mainButtonActionType[locale]) + 1;
      } else {
        mainButtonActionTypeRef.current.selectedIndex = 0;
      }
  }, [locale]);

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      formErrors.constructor === Object &&
      isSubmit
    ) {
      uploadData();
    } else {
      setIsSubmit(false);
    }
  }, [formErrors, refreshVal]);

  const changeButtonActionType = (e) => {
    // if (mainButtonTextField.current) {
    const value = Number(e.target.value);
    if (value === 5 || value === 4) {
      mainButtonTextField.current.value = "";
      formData.mainButtonText[locale] = "";
      mainButtonTextField.current.disabled = false;
    } else if (value !== 4 || value !== 5) {
      mainButtonTextField.current.value = mainButtonActionType[value];
      formData.mainButtonText[locale] = mainButtonActionType[value];
      mainButtonTextField.current.disabled = true;
    }
    handleChange(e);
    // }
  };

  useEffect(() => {
    refresh();
  }, []);

  if (
    window?.customer?.licenseType === -1 ||
    window?.customer?.licenseType === -1
  ) {
    navigate("/dashboard");
    return <>Not Authenticated, Redirecting..</>;
  }
  if (loading) return <>Loading...</>;

  return (
    <>
      {openLocationSelector && (
        <LocationSelectorModel
          setLocation={setLocation}
          onClose={() => setOpenLocationSelector(false)}
          DefaultLocation={location}
        />
      )}

      <div className="max-w-[100vw] bg-bgColor ">
        <div className="md:pt-[53px] md:pl-[288px] md:pr-[46px]">
          <div className="flex justify-center gap-4 mb-8">
            <button
              className={`relative w-full py-4 rounded-xl ${
                locale === "it" && "bg-customBlue text-white"
              } font-semibold`}
              style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.25)" }}
              onClick={() => setLocale("it")}
            >
              {locale === "en" && <RequiredStar />}
              Italiano
            </button>
            <button
              className={`relative w-full py-4 rounded-xl ${
                locale === "en" && "bg-customBlue text-white"
              } font-semibold`}
              style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.25)" }}
              onClick={() => setLocale("en")}
            >
              {locale === "it" && <RequiredStar />}
              Inglese
            </button>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col pt-[59px] px-[22px] mb-[15px] md:pt-0 md:px-0 md:basis-2/5">
              <DragZone
                images={
                  // window.customer.eventRequest !== 0 &&
                  formLoading ? [] : formData.imageUrls[locale]
                }
                setImages={(image) => {
                  formData.imageUrls[locale] = image;
                  refresh();
                }}
                onDelete={(index) => {
                  formData.imageUrls[locale].splice(index, 1);
                }}
                isRed={
                  formData.imageUrls[locale].length === 0 &&
                  formErrors.general !== ""
                }
              />
            </div>
            <div className="flex flex-col px-[22px] mb-[60px] md:basis-3/5">
              <div
                style={{
                  border:
                    formErrors.shopNameError !== "" &&
                    formData.eventName[locale] === "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={
                      formErrors.shopNameError !== "" &&
                      formData.eventName[locale] === ""
                    }
                  />
                  <img
                    src={NameFieldIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  name="eventName"
                  value={formData.eventName[locale]}
                  onChange={handleChange}
                  placeholder="Nome Evento"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formErrors.general !== "" &&
                    formData.shopTag === "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={formErrors.general !== "" && formData.shopTag === ""}
                  />
                  <img
                    src={NameFieldIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <select
                  onChange={(e) => {
                    formData.shopTag = e.target.value;
                    refresh();
                  }}
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none invalid:text-[#6666]"
                  required
                >
                  <option disabled selected hidden valid="">
                    Categoria dell'evento
                  </option>
                  <option value="Sconti Speciali">Sconti Speciali</option>
                  <option value="Sportivi">Sportivi</option>
                  <option value="Spettacoli">Spettacoli</option>
                  <option value="Beneficenza">Beneficenza</option>
                  <option value="Feste Patronali">Feste Patronali</option>
                  <option value="Feste">Feste</option>
                  <option value="Fiera">Fiera</option>
                  <option value="Feste a Tema">Feste a Tema</option>
                  <option value="Non rientra in nessuna di queste categorie">
                    Non rientra in nessuna di queste categorie
                  </option>
                </select>
              </div>

              {formErrors?.shopNameError?.length > 0 ? (
                <span className="text-red-400 font-poppins font-normal text-sm">
                  {formErrors.shopNameError}
                </span>
              ) : null}
              <div
                style={{
                  border:
                    formErrors.descriptionError !== "" &&
                    formData.eventDescription[locale] === "" &&
                    "2px red solid",
                }}
                className="relative flex space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg border-[2px] border-lightPurple mb-[16px]"
              >
                <span className="absolute right-2 top-2">
                  {Math.abs(formData.eventDescription[locale].length - 550)}
                </span>
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={
                      formErrors.descriptionError !== "" &&
                      formData.eventDescription[locale] === ""
                    }
                  />
                  <img
                    src={DescriptionFieldIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <textarea
                  type="text"
                  rows={4}
                  name="eventDescription"
                  value={formData.eventDescription[locale]}
                  onChange={handleChange}
                  placeholder="Descrizione"
                  maxLength={550}
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      formData.eventDescription[locale] += "\\\\\\n";
                    }
                  }}
                />
              </div>
              {formErrors?.descriptionError?.length > 0 ? (
                <span className="text-red-400 font-poppins font-normal text-sm">
                  {formErrors.descriptionError}
                </span>
              ) : null}

              <div
                style={{
                  border:
                    formErrors.general !== "" &&
                    !formData.startDate &&
                    "2px red solid",
                }}
                className="relative flex gap-4 items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[6px] border-[2px] border-lightPurple"
              >
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={formErrors.general !== "" && !formData.startDate}
                  />
                  <img
                    src={ClockIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  onFocus={(e) => (e.target.type = "datetime-local")}
                  placeholder="Data Inizio"
                  name="startDate"
                  onChange={handleChange}
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formErrors.descriptionError !== "" &&
                    !formData.endDate &&
                    "2px red solid",
                  opacity: !formData.startDate ? 0.4 : 1,
                }}
                className="relative flex gap-4 items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[6px] border-[2px] border-lightPurple"
              >
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={
                      formErrors.descriptionError !== "" && !formData.endDate
                    }
                  />
                  <img
                    src={ClockIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  onFocus={(e) => (e.target.type = "datetime-local")}
                  placeholder="Data Fine"
                  name="endDate"
                  onChange={handleChange}
                  disabled={!formData.startDate}
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none "
                  min={formData.startDate}
                />
              </div>
              <div
                style={{
                  border:
                    formErrors.phoneNumberError !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.phoneNumber[locale] == 0 &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
              >
                <div className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] ">
                  <RequiredStar
                    isRed={
                      formErrors.phoneNumberError !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.phoneNumber[locale] == 0
                    }
                  />
                  <img
                    src={PhoneIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="number"
                  name="phoneNumber"
                  // pattern="^[0-9]+$"
                  value={formData.phoneNumber[locale]}
                  onChange={handleChange}
                  placeholder="Numero di Telefono"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              {formErrors.phoneNumberError?.length > 0 ? (
                <span className="text-red-400 font-poppins font-normal text-sm">
                  {formErrors.phoneNumberError}
                </span>
              ) : null}

              {window.customer.shopCategory === "Culture" && (
                <>
                  <div
                    style={{
                      border:
                        formErrors.general !== "" &&
                        formData.address[locale] === "" &&
                        "2px red solid",
                    }}
                    className="relative flex items-center space-x-[10px] bg-gray4 py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                  >
                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          formData.address[locale] === ""
                        }
                      />
                      <img
                        src={LocationIcon}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Via, Numero, Città"
                      className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                      onChange={(e) => {
                        formData.address.it = e.target.value;
                        formData.address.en = e.target.value;
                        refresh();
                      }}
                      name="address"
                      value={formData.address[locale]}
                    />
                  </div>
                  <div
                    style={{
                      border:
                        location.lat === 40.72846578226386 &&
                        formErrors.general !== "" &&
                        "2px red solid",
                    }}
                    onClick={() => setOpenLocationSelector(true)}
                    className="relative bg-lightPurple md:bg-white rounded-lg font-poppins font-bold md:font-semibold text-center w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-bgColor md:text-customBlue pt-[16px] pb-[13px] hover:cursor-pointer mb-[15px] md:border-[3px] md:border-customBlue"
                  >
                    <RequiredStar
                      isRed={
                        location.lat === 40.72846578226386 &&
                        formErrors.general !== ""
                      }
                    />
                    {location.lat !== 40.72846578226386
                      ? "Punto sulla Mappa selezionato"
                      : "Seleziona località dell’evento"}
                  </div>
                </>
              )}

              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "4" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.firstLinkName[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div>
                  <span className="absolute right-2 top-2">
                    {" "}
                    {Math.abs(formData.firstLinkName[locale].length - 30)}
                  </span>

                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {formData.mainButtonActionType[locale] === "4" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.firstLinkName[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon1}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="firstLinkName"
                  value={formData.firstLinkName[locale]}
                  onChange={handleChange}
                  maxLength={30}
                  placeholder="Nome del primo link principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "4" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.firstLinkDescription[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div>
                  <span className="absolute right-2 top-2">
                    {Math.abs(
                      formData.firstLinkDescription[locale].length - 30
                    )}
                  </span>
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {formData.mainButtonActionType[locale] === "4" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.firstLinkDescription[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon1}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="firstLinkDescription"
                  maxLength={30}
                  value={formData.firstLinkDescription[locale]}
                  onChange={handleChange}
                  placeholder="Descrizione del primo link principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "4" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.firstLink[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  {formData.mainButtonActionType[locale] === "4" && (
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.firstLink[locale] == ""
                      }
                    />
                  )}
                  <img
                    src={ApplicationIcon1}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  name="firstLink"
                  value={formData.firstLink[locale]}
                  onChange={handleChange}
                  placeholder="Primo Link Principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>

              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "5" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.secondLinkName[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div>
                  <span className="absolute right-2 top-2">
                    {Math.abs(formData.secondLinkName[locale].length - 30)}
                  </span>
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {formData.mainButtonActionType[locale] === "5" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.secondLinkName[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon2}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="secondLinkName"
                  value={formData.secondLinkName[locale]}
                  onChange={handleChange}
                  maxLength={30}
                  placeholder="Nome del secondo link principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "5" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.secondLinkDescription[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div>
                  <span className="absolute right-2 top-2">
                    {Math.abs(
                      formData.secondLinkDescription[locale].length - 30
                    )}
                  </span>
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {formData.mainButtonActionType[locale] === "5" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.secondLinkDescription[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon2}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="secondLinkDescription"
                  value={formData.secondLinkDescription[locale]}
                  onChange={handleChange}
                  maxLength={30}
                  placeholder="Descrizione del secondo link principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonActionType[locale] === "5" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.secondLink[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  {formData.mainButtonActionType[locale] === "5" && (
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.secondLink[locale] == ""
                      }
                    />
                  )}
                  <img
                    src={ApplicationIcon2}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  name="secondLink"
                  value={formData.secondLink[locale]}
                  onChange={handleChange}
                  placeholder="Secondo Link Principale"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>

              <div
                style={{
                  border:
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.mainButtonText[locale] == "" &&
                    "2px red solid",
                  opacity:
                    Number(formData.mainButtonActionType[locale]) <= 3 ||
                    Number(formData.mainButtonActionType[locale]) === 6 ||
                    Number(formData.mainButtonActionType[locale]) === 0
                      ? 0.4
                      : 1,
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  <RequiredStar
                    isRed={
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.mainButtonText[locale] == ""
                    }
                  />
                  <img
                    src={TextIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Testo Pulsante Principale"
                  name="mainButtonText"
                  ref={mainButtonTextField}
                  onChange={handleChange}
                  value={formData.mainButtonText[locale] || ""}
                  disabled={
                    mainButtonActionTypeRef?.current?.selectedIndex <= 4 ||
                    mainButtonActionTypeRef?.current?.selectedIndex === 7 ||
                    mainButtonActionTypeRef?.current?.selectedIndex === 0
                  }
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none disabled:opacity-40"
                />
              </div>
              <div
                style={{
                  marginBottom:
                    formData.mainButtonText[locale] === "Prenota ora" ? 5 : 15,
                  border:
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.mainButtonActionType[locale] === "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  <RequiredStar
                    isRed={
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.mainButtonActionType[locale] === ""
                    }
                  />
                  <img
                    src={PrincipalFieldIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>
                <select
                  ref={mainButtonActionTypeRef}
                  name="mainButtonActionType"
                  onChange={changeButtonActionType}
                  // value={formData.mainButtonActionType[locale] || ""}
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none invalid:text-[#666666]"
                  required
                >
                  <option value="" disabled hidden selected>
                    Azione Pulsante Principale
                  </option>

                  {mainButtonActionType.map((opt, index) => (
                    <option value={index}>{opt}</option>
                  ))}
                </select>
              </div>
              {(formData.mainButtonActionType[locale] === 0 ||
                formData.mainButtonText[locale] === "Prenota ora") && (
                <div
                  style={{
                    border:
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.emailToReceive[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.emailToReceive[locale] == ""
                      }
                    />
                    <img
                      src={EmailIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Email dove ricevere le prenotazioni"
                    name="emailToReceive"
                    onChange={(e) => {
                      formData.emailToReceive[locale] = e.target.value;
                      refresh();
                    }}
                    value={formData?.emailToReceive?.[locale] || ""}
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
              )}

              <div
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                style={{
                  border:
                    formData.mainButtonText[locale] ===
                      "Seguici su Instagram" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.instagramLink[locale] == "" &&
                    "2px red solid",
                }}
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  {formData.mainButtonText[locale] ===
                    "Seguici su Instagram" && (
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.instagramLink[locale] == ""
                      }
                    />
                  )}
                  <img
                    src={InstagramIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>

                <input
                  type="text"
                  name="instagramLink"
                  value={formData.instagramLink[locale]}
                  onChange={handleChange}
                  placeholder="Link Instagram"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonText[locale] === "Seguici su Facebook" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.facebookLink[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  {formData.mainButtonText[locale] ===
                    "Seguici su Facebook" && (
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.facebookLink[locale] == ""
                      }
                    />
                  )}
                  <img
                    src={FacebookIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>

                <input
                  type="text"
                  name="facebookLink"
                  value={formData.facebookLink[locale]}
                  onChange={handleChange}
                  placeholder="Link Facebook"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                style={{
                  border:
                    formData.mainButtonText[locale] === "Scrivici una mail" &&
                    formErrors.general !== "" &&
                    // eslint-disable-next-line eqeqeq
                    formData.email[locale] == "" &&
                    "2px red solid",
                }}
                className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
              >
                <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                  {formData.mainButtonText[locale] === "Scrivici una mail" && (
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.email[locale] == ""
                      }
                    />
                  )}
                  <img
                    src={EmailIcon}
                    alt="icon"
                    className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                  />
                </div>

                <input
                  type="text"
                  name="email"
                  value={formData.email[locale]}
                  onChange={handleChange}
                  placeholder="Email"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>

              {formErrors?.general?.length > 0 ? (
                <span className="text-red-400 font-poppins font-normal text-sm">
                  {formErrors.general}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full pb-[40px] md:pb-[68px] flex items-center justify-center m-d:m-l-[130px]">
          <button
            className="w-full md:w-[55%] rounded-[14px] font-poppins font-bold text-bgColor text-[16px] leading-[24px] bg-customBlue pt-[16px] pb-[13px] disabled:opacity-40"
            disabled={formLoading}
            onClick={async () => {
              setFormErrors({
                shopNameError: "",
                descriptionError: "",
                phoneNumberError: "",
                general: "",
              });

              if (newButtonText) {
                window.open("mailto:supporto@infoischia.com");
              } else {
                handleSubmit();
              }
            }}
          >
            {formLoading ? <LoadingIcon /> : newButtonText || "Invia Evento"}
          </button>
        </div>
      </div>
    </>
  );
}

const LocationSelectorModel = ({ setLocation, onClose, DefaultLocation }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <>
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-transparent backdrop-blur-[10px] opacity-100"
        onClick={onClose}
      ></div>
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-black backdrop-blur-[10px] opacity-40"
        onClick={onClose}
      ></div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-max bg-white z-30 rounded-xl p-4">
        <LocationSelector
          onLocationChange={(newLocation) => setLocation(newLocation)}
          onClose={onClose}
          DefaultLocation={DefaultLocation}
        />
      </div>
    </>
  );
};
