import React from "react";
import { Col, Row } from "antd";
import "./style.css";
import Logo5 from "../../images/infoischia logo icon.png";
import Icon4 from "../../images/Raggruppa 467.png";
import Icon5 from "../../images/Raggruppa 468.png";
import { Apple, GooglePlay } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="maindivfooter">
      <div>
        <Row justify="space-between " className="flex items-start">
          <Col lg={8} md={8} xs={8}>
            <>
              <div className="centerfooter22">
                <div className="centerfooter22">
                  <div>
                    <img
                      className="img-fluid imgfooterlog"
                      src={Logo5}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="centerfooter22">
                      <p className="infotext22">Info</p>
                      <p className="infotext122">Ischia</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Col>
          <Col lg={8} md={8} xs={8} className="mobile:hidden tablet:hidden">
            <div className="centerfooter2244">
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/signIn"
                  onClick={() => window.scrollY(0)}
                >
                  <p className="textacc">
                    Accedi a <a className="aatext">Info</a>Ischia
                    <a className="aatext">Pro</a>
                  </p>
                </Link>
                <p
                  className="textacc1 cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.iubenda.com/termini-e-condizioni/20086812",
                      "_blank"
                    )
                  }
                >
                  Termini e Condizioni
                </p>
                <p
                  className="textacc1 cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.iubenda.com/privacy-policy/20086812",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy dell'app
                </p>
                <p className="textacc2">About Us</p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} xs={8}>
            <div className="centerfooter">
              <div>
                <p className="textacc2">Ottieni l’app</p>
                <div>
                  <button
                    className="flex items-center divdis33 "
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/it/app/infoischia/id6444228299",
                        "_blank"
                      )
                    }
                  >
                    <Apple size={36} className=" iconbtt" />
                    <div className="flex flex-col gap-4 mb-3">
                      <p className="textdis33">Disponibile su</p>
                      <p className="textdis233">App Store</p>
                    </div>
                  </button>
                  <button
                    className="flex items-center divdis2233"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.app.infoischia",
                        "_blank"
                      )
                    }
                  >
                    <GooglePlay size={36} className="iconbtt" />
                    <div className="flex flex-col gap-4 mb-3">
                      <p className="textdis33">Disponibile su</p>
                      <p className="textdis233">Play Store</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="centerfooter2244 w-full hidden tablet:block mobile:block">
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to="/signIn"
              onClick={() => window.scrollY(0)}
            >
              <p className="textacc">
                Accedi a <a className="aatext">Info</a>Ischia
                <a className="aatext">Pro</a>
              </p>
            </Link>
            <p
              className="textacc1 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.iubenda.com/termini-e-condizioni/20086812",
                  "_blank"
                )
              }
            >
              Termini e Condizioni
            </p>
            <p
              className="textacc1 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.iubenda.com/privacy-policy/20086812",
                  "_blank"
                )
              }
            >
              Privacy Policy dell'app
            </p>
            <p className="textacc2">About Us</p>
          </div>
        </div>
      </div>
      <hr className="hrhr"></hr>
      <Row justify="space-between">
        <Col lg={10} md={10} xs={10}>
          <div className="footersub  ">
            <div>
              <p className="copyext">
                © Copyright 2022. Tutti i diritti riservati.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={10} md={10} xs={10}>
          <div className="footersub22">
            <div className="footersubdiv00">
              <img
                className="img-fluid imgimg3456 cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/infoischia/?igshid=YmMyMTA2M2Y%3D",
                    "_blank"
                  )
                }
                src={Icon4}
                alt=""
              />
              <img
                className="img-fluid imgimg3456 cursor-pointer"
                onClick={() =>
                  window.open("https://www.facebook.com/infoischia", "_blank")
                }
                src={Icon5}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
