import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import PrincipalFieldIcon from "../../images/principalFieldIcon.png";
import { useNavigate } from "react-router-dom";
import months from "../../utils/getMonths";
import formatDate from "../../utils/formatDate";

const numberInWordMonths = [
  "firstMonth",
  "secondMonth",
  "thirdMonth",
  "fourthMonth",
  "fifthMonth",
  "sixthMonth",
  "seventhMonth",
  "eighthMonth",
  "ninthMonth",
  "tenthMonth",
  "eleventhMonth",
  "twelvethMonth",
];

export default function Eventi({ loading, setLoading, eventPublication }) {
  const [events, setEvents] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [selectedTimestamp, setSelectedTimestamp] = useState(0);
  const [formattedEvents, setFormattedEvents] = useState({});
  const [refreshValue, setRefreshValue] = useState(0);
  const [selectLoading, setSelectLoading] = useState(false);
  const [showCreateEventButton, setShowCreateEventButton] = useState(true);
  const [eventRequest, setEventRequest] = useState(
    window?.customer?.eventRequest
  );

  const refresh = () => setRefreshValue(Math.random());

  const navigate = useNavigate();

  const getEvents = async () => {
    const data = await getDocs(
      collection(db, "Customers", window.customerId, "events")
    );

    setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

    setTimestamps(
      getTimestamps(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    setFormattedEvents(
      getFormattedEvents(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      )
    );

    getSelectedDocumentId(
      getFormattedEvents(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      )
    );
  };

  const getTimestamps = (events) => {
    const timestamps = events.map((event) => {
      const time = new Date(event.eventStartTimestamp.seconds * 1000);

      return {
        text: `${time.getDate()} ${
          months[time.getMonth()]
        } ${time.getFullYear()}`,
        timestamp: time,
      };
    });

    return timestamps.sort((x, y) => x.timestamp - y.timestamp);
  };

  const getFormattedEvents = (events) => {
    const formattedEvents = {};
    events.map((event) => {
      const time = new Date(event.eventStartTimestamp.seconds * 1000);
      const timeText = `${time.getDate()} ${
        months[time.getMonth()]
      } ${time.getFullYear()}`;

      formattedEvents[timeText] = event;
    });

    return formattedEvents;
  };

  const getSelectedDocumentId = async (formattedEvents) => {
    const customer = window.customer;

    if (customer?.eventSelectedDocumentId) {
      const keys = Object.keys(formattedEvents);

      keys.map((key, i) => {
        if (formattedEvents[key].id === customer.eventSelectedDocumentId) {
          setSelectedTimestamp(i);
        }
      });
    }
  };

  const updatedSelectEvent = async (id) => {
    await updateDoc(doc(db, "Customers", window.customerId), {
      eventSelectedDocumentId: id,
    });
  };

  const getCustomer = async () => {
    try {
      const document = await getDoc(doc(db, "Customers", window.customerId));
      if (document.exists()) {
        window.customer = document.data();
        localStorage.setItem("customer", JSON.stringify(document.data()));
        setEventRequest(window.customer.eventRequest);
        if (window.customer.eventRequest === 0) setShowCreateEventButton(false);
      } else throw new Error("No Customer found with the matching id.");
      refresh();
    } catch (err) {
      console.error(err);
      refresh();
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };

  const startUp = async () => {
    const localCustomerId =
      localStorage.getItem("customerId") ||
      sessionStorage.getItem("customerId");

    if (localCustomerId !== null) window.customerId = localCustomerId;

    if (!window.customerId) return window.location.replace("/signIn");
    await getCustomer();
    getEvents();
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    startUp();
  }, []);

  // if (!window.customerId) return window.location.replace("/signIn");

  if (
    window?.customer?.licenseType === -1 ||
    window?.customer?.licenseType === -1
  ) {
    navigate("/dashboard");
    return <>Not Authenticated, Redirecting..</>;
  }
  if (loading) {
    return <>loading...</>;
  }

  return (
    <div className="bg-bgColor mobile_tablet:mt-8 min-h-screen ">
      <div className="flex flex-col items-center md:space-x-[40px] h-full md:ml-[288px] md:pt-[53px] md:mr-[90px] md:mb-[40px]">
        <div
          className="bg-[#EFEDFF] w-full rounded-xl p-8 mobile_tablet:p-2 grid place-items-center"
          style={{
            opacity: events.length === 0 ? 0.4 : 1,
            pointerEvents: events.length === 0 ? "none" : "auto",
          }}
        >
          <div className="flex gap-[164px] mobile_tablet:gap-8 text-xl text-customBlue mb-8">
            <div
              onClick={() => {
                if (selectedTimestamp !== 0) {
                  setSelectedTimestamp(selectedTimestamp - 1);
                }
              }}
              style={{
                opacity: selectedTimestamp === 0 ? 0.4 : 1,
                cursor: selectedTimestamp === 0 ? "not-allowed" : "pointer",
              }}
            >
              {"<"}
            </div>
            <div className="font-bold font-poppins">
              {events.length === 0 ? (
                "Nessun Evento"
              ) : (
                <div>{timestamps?.[selectedTimestamp]?.text}</div>
              )}
            </div>
            <div
              style={{
                opacity: selectedTimestamp === timestamps.length - 1 ? 0.4 : 1,
                cursor:
                  selectedTimestamp === timestamps.length - 1
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => {
                if (selectedTimestamp !== timestamps.length - 1) {
                  setSelectedTimestamp(selectedTimestamp + 1);
                }
              }}
            >
              {">"}
            </div>
          </div>
          <div
            className="bg-[#A3A2D8] w-full h-auto rounded-3xl p-8 mobile_tablet:p-2 flex mobile_tablet:flex-col gap-4 justify-between"
            style={{ boxShadow: "inset 0px 0px 30px rgba(0,0,0,0.25)" }}
          >
            <div className="grid place-items-center">
              {!formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                ?.images ? (
                <div className="min-w-[350px] h-[350px] mobile_tablet:min-w-[150px] mobile_tablet:h-[150px] bg-black rounded-xl text-white text-2xl grid place-items-center font-poppins">
                  No Image Found :(
                </div>
              ) : (
                <img
                  src={
                    formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                      ?.images[0]
                  }
                  className="min-w-[350px] min-h-[350px] mobile_tablet:min-w-[150px] mobile_tablet:h-[150px]"
                  alt="EVENT_IMAGE"
                />
              )}
            </div>
            <div className="w-full">
              <div className="flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple">
                <img
                  src={PrincipalFieldIcon}
                  alt="icon"
                  className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                />
                <input
                  type="text"
                  value={
                    formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                      ?.eventName.it
                  }
                  placeholder="Evento Nome"
                  disabled
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div className="flex space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg border-[2px] border-lightPurple mb-[16px]">
                <img
                  src={PrincipalFieldIcon}
                  alt="icon"
                  className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                />
                <textarea
                  type="text"
                  rows={4}
                  disabled
                  name="description"
                  value={
                    formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                      ?.eventDescription.it
                  }
                  // value={formData.description[locale]}
                  // onChange={handleChange}
                  placeholder="Descrizione"
                  className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                />
              </div>
              <div
                className=" flex justify-between gap-4"
                style={{ display: events.length === 0 ? "none" : "flex" }}
              >
                <button
                  onClick={async () => {
                    setSelectLoading(true);
                    await updatedSelectEvent(
                      formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                        ?.id
                    );
                    setTimeout(async () => {
                      setSelectLoading(false);
                    }, 4000);
                  }}
                  disabled={selectLoading}
                  className="w-full border-white text-white font-bold border-[3px] py-4 rounded-xl text-xl mobile_tablet:text-sm disabled:opacity-40"
                >
                  {selectLoading ? "Evento Selezionato!" : "Seleziona Evento"}
                </button>
                <button
                  className={`w-full py-4 rounded-xl ${
                    formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                      ?.eventActive === 0
                      ? "bg-customBlue"
                      : "bg-red-500"
                  } text-white font-bold text-xl mobile_tablet:text-sm disabled:opacity-40`}
                  disabled={
                    new Date(
                      formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                        ?.eventStartTimestamp?.seconds * 1000
                    ) < new Date()
                  }
                  onClick={async () => {
                    if (
                      formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                        ?.eventActive === 0
                    ) {
                      await updateDoc(
                        doc(
                          db,
                          "Customers",
                          window.customerId,
                          "events",
                          formattedEvents?.[
                            timestamps?.[selectedTimestamp]?.text
                          ]?.id
                        ),
                        { eventActive: -1 },
                        { merge: true }
                      );
                      if (
                        formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                          ?.eventMCDocumentId
                      ) {
                        await updateDoc(
                          doc(
                            db,
                            "Events",
                            formattedEvents?.[
                              timestamps?.[selectedTimestamp]?.text
                            ]?.eventMCDocumentId
                          ),
                          { eventActive: -1 },
                          { merge: true }
                        );
                      }
                    } else {
                      await updateDoc(
                        doc(
                          db,
                          "Customers",
                          window.customerId,
                          "events",
                          formattedEvents?.[
                            timestamps?.[selectedTimestamp]?.text
                          ]?.id
                        ),
                        { eventActive: 0 },
                        { merge: true }
                      );

                      if (
                        formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                          ?.eventMCDocumentId
                      ) {
                        await updateDoc(
                          doc(
                            db,
                            "Events",
                            formattedEvents?.[
                              timestamps?.[selectedTimestamp]?.text
                            ]?.eventMCDocumentId
                          ),
                          { eventActive: 0 },
                          { merge: true }
                        );
                      }
                    }
                    await getEvents();
                  }}
                >
                  {formattedEvents?.[timestamps?.[selectedTimestamp]?.text]
                    ?.eventActive === 0
                    ? "Riabilita Evento"
                    : "Annulla Evento"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {showCreateEventButton && (
          <button
            className="mt-4 bg-customBlue text-white py-4 rounded-xl w-full max-w-[550px] mobile_tablet:w-[250px] mobile_tablet:text-sm text-xl font-bold font-poppins disabled:opacity-40"
            onClick={() => navigate("/eventi/create")}
            disabled={eventPublication === 0}
          >
            Crea Evento
          </button>
        )}
        {(eventRequest === 0 ||
          (eventRequest === 1 &&
            new Date(
              new Date(
                window.customer?.eventRequestMap?.lastApprovedTimestamp
                  ?.seconds * 1000
              ).setDate(
                new Date(
                  window.customer?.eventRequestMap?.lastApprovedTimestamp
                    ?.seconds * 1000
                ).getDate() + 10
              )
            ) > new Date())) && (
          <div className="border-2 flex flex-col border-customBlue px-40 rounded-xl py-4 mt-4">
            <span className="text-customBlue font-semibold text-xl text-center">
              {(() => {
                if (window.customer.eventRequest === 1) {
                  return (
                    <>
                      {window.customer.eventRequestMap.eventName.it} già
                      approvato
                    </>
                  );
                }
                if (window.customer.eventRequest === -1) {
                  return (
                    <>
                      {window.customer.eventRequestMap.eventName.it} annullato
                    </>
                  );
                } else
                  return (
                    <>
                      {window.customer.eventRequestMap.eventName.it} inviato in
                      attesa di approvazione
                    </>
                  );
              })()}
            </span>

            <span className="text-center font-semibold text-gray-400">
              Inviata il{" "}
              {formatDate(window.customer.eventRequestMap.lastSentTimestamp)}
            </span>
            {window.customer.eventRequest === 1 && (
              <span className="text-center font-semibold text-gray-400">
                Approvato il{" "}
                {formatDate(
                  window.customer.eventRequestMap.lastApprovedTimestamp
                )}
              </span>
            )}

            <button
              className={`bg-red-500 text-white rounded-lg px-4 py-1 mt-2 font-semibold disabled:opacity-40`}
              disabled={
                window.customer.eventRequest === 1 ||
                window.customer.eventRequest === -1
              }
              onClick={async () => {
                const customerRef = await getDoc(
                  doc(db, "Customers", window.customerId)
                );
                const customer = customerRef.data();

                window.customer = customer;
                refresh();

                if (window.customer.eventRequest === 0) {
                  setEventRequest(0);
                  setShowCreateEventButton(true);
                  await updateDoc(doc(db, "Customers", window.customerId), {
                    eventRequest: -1,
                  });
                  window.customer.eventRequest = -1;
                  if (window.customer.eventRequestMap?.eventMinus === -1) {
                    await updateDoc(doc(db, "Customers", window.customerId), {
                      eventNum: increment(1),
                    });
                  } else if (
                    window.customer.eventRequestMap?.eventMinus !== -1
                  ) {
                    window.customer.freeEvent[
                      numberInWordMonths[
                        window.customer.eventRequestMap?.eventMinus
                      ]
                    ] = true;

                    await updateDoc(doc(db, "Customers", window.customerId), {
                      freeEvent: window.customer.freeEvent,
                    });
                  }
                  refresh();
                } else {
                  setEventRequest(window.customer.eventRequest);
                }

                refresh();
              }}
            >
              {(() => {
                if (eventRequest === 1) {
                  return "Annulla Invio";
                }
                if (eventRequest === -1) {
                  return "Annullato";
                } else return "Annulla Invio";
              })()}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
