import React from "react";
import CheckIcon from "../../../images/checkBlue.png";

const RecentActivitiesCard = ({ text, date, timestamp }) => {
  return (
    <div className="flex items-center space-x-1 md:space-x-4 bg-white rounded-lg w-full shadow-lg py-2 px-2">
      <div className="flex justify-between  w-full items-center">
        <div className="flex w-full">
          <img
            src={CheckIcon}
            alt="check"
            className="h-[20px] w-[15px] tablet:w-[10px] mobile:w-[10px] tablet:h-[10px] mobile:h-[10px] object-contain mobile:object-cover tablet:object-cover mr-2"
          />
          <span className="text-black2 w-[65%] md:w-[80%] font-poppins font-normal text-sm tablet:text-sm mobile:text-[10px] leading-[9px] md:leading-[20px]">
            {text}
          </span>
        </div>

        <div className=" whitespace-nowrap font-poppins font-bold text-[6px] md:text-[14px] text-black2 leading-[9px] md:leading-[20px]">
          {date} alle {timestamp}
        </div>
      </div>
    </div>
  );
};

export default RecentActivitiesCard;
