import React from "react";
import "./style.css";
import { Row, Col } from "antd";
import image1 from "../../images/Raggruppa 516.png";
import image2 from "../../images/Raggruppa 512.png";
import Mblview from "../../images/Raggruppa 556.png";
import Mblview2 from "../../images/Raggruppa 563.png";

function Component1() {
  return (
    <>
      <div className="headtext22">
        <h1 className="text1">Info</h1>
        <h1 className="text2">Ischia</h1>
        <h4 className="textsmall">Pro</h4>
      </div>
      <div className="headtext2">
        <h1 className="text2">
          Scopri tutti i vantaggi di
          <div className="hidden mobile:block tablet:block" />
          <a className="text12">Info</a>
          <a className="text2">Ischia</a>
          <a className="textsmall2 ">Pro</a>
        </h1>
      </div>
      <Row justify="space-evenly">
        <Col
          lg={7}
          className="pl-[6rem] tablet:pl-0 mobile:pl-0 tablet:text-center mobile:text-center w-full"
        >
          <h2 className="text22">Un potente gestionale!</h2>
          <div className="textalligner">
            <p className="paratext">
              Ottieni una panoramica sugli
              <br /> eventi e sulla tua vetrina su
              <a className="paratext1"> Info</a>
              <a className="paratext2">Ischia</a>
              <br />
              <br />
              Ottieni le statistiche sugli eventi e <br /> sulla tua pagina in
              tempo reale.
              <br /> Tieni tutto a portata di mano!
            </p>
          </div>
        </Col>
        <Col lg={15}>
          <div className="imagdiv flex flex-row-reverse tablet:hidden mobile:hidden">
            <img className="img-fluid  img1" src={image1} />
            <img className="img-fluid  img2" src={image2} />
          </div>
          <div className="h-[500px] w-screen mblviewimg scale-110 mobile:scale-100">
            <img
              classNamse="img-fluid imgmblview"
              className="absolute top-[-250px] right-0"
              src={Mblview}
              alt=""
            />
            <img
              classNamse="img-fluid imgmblview1"
              className="absolute top-[-250px] left-0"
              src={Mblview2}
              alt=""
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Component1;
