import { useState, useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./pages/Home";
import Vetrina from "./pages/Vetrina";
import { useWindowSize } from "./utils/WindowResizeHook";
import ReactPixel from "react-facebook-pixel";
import Eventi from "./pages/Eventi";
import Create from "./pages/Eventi/Create";
import PaymentPage from "./pages/payment";
import SignInPage from "./pages/signin";
import HomePage from "./pages";
import ProHomePage from "./pages/pro";
import InvalidForm from "./pages/invalid";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import DeleteData from "./pages/deleteData";

window.dashboard_paths = [
  "/dashboard",
  "/vetrina",
  "/eventi",
  "/eventi/create",
];
window.landing_paths = ["/", "/signin", "/invalid"];
window.payment_paths = ["/payment"];

const numberInWordMonths = [
  "firstMonth",
  "secondMonth",
  "thirdMonth",
  "fourthMonth",
  "fifthMonth",
  "sixthMonth",
  "seventhMonth",
  "eighthMonth",
  "ninthMonth",
  "tenthMonth",
  "eleventhMonth",
  "twelvethMonth",
];

function App() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [loading, setLoading] = useState(true);

  const [width, height] = useWindowSize();
  const [eventPublication, setEventPublication] = useState(0);
  const [showSideBar, setShowSideBar] = useState(
    window.customerId ? true : false
  );
  const location = useLocation();
  const [selected, setSelected] = useState(1);

  const pageChangeButtonRef = useRef();

  const [refreshVal, setRefreshVal] = useState(0);
  const [showCookiePopup, setShowCookiePopup] = useState(true);

  const refresh = () => setRefreshVal(Math.random());

  const getCustomer = async () => {
    try {
      const document = await getDoc(doc(db, "Customers", window.customerId));
      if (document.exists()) {
        window.customer = document.data();
        localStorage.setItem("customer", JSON.stringify(window.customer));
        sessionStorage.setItem("customer", JSON.stringify(window.customer));
      } else throw new Error("No Customer found with the matching id.");
    } catch (err) {
      console.error("Error: " + err.message);
    }
  };
  useEffect(() => {
    if (window.customerId) {
      getDoc(doc(db, "Customers", window.customerId))
        .then((document) => {
          if (document.data()?.licenseTimestamp?.seconds) {
            const licenseTimestamp = new Date(
              window.customer.licenseTimestamp.seconds * 1000
            );
            const timestamp = new Date(
              licenseTimestamp.setFullYear(licenseTimestamp.getFullYear() - 1)
            );
            const currentTimestamp = new Date();

            const calculatedMonthName =
              numberInWordMonths[
                Math.abs(timestamp.getMonth() - currentTimestamp.getMonth())
              ];

            if (window.customer.freeEvent[calculatedMonthName] === true) {
              setEventPublication(document.data().eventNum + 1);
            } else {
              setEventPublication(document.data().eventNum);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [window.customerId, selected]);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    setShowSideBar(window.customerId ? true : false);
  }, [window.customerId]);

  useEffect(() => {
    ReactPixel.init("866890061283523");
    if (localStorage.getItem("cookies") === "true") {
      setShowCookiePopup(false);
    } else {
      ReactPixel.revokeConsent();
    }
  }, []);

  useEffect(() => {
    console.log("PageChanged");

    if (pageChangeButtonRef.current) {
      pageChangeButtonRef.current.click();
    }
  }, [location, pageChangeButtonRef]);

  // useEffect(() => {
  //   if (!window.customer) {
  //     (async () => {
  //       const localCustomerId =
  //         localStorage.getItem("customerId") ||
  //         sessionStorage.getItem("customerId");
  //       const localCustomer =
  //         localStorage.getItem("customer") ||
  //         sessionStorage.getItem("customer");

  //       if (localCustomerId !== null) window.customerId = localCustomerId;
  //       if (localCustomer !== null) window.customer = localCustomer;
  //     })();
  //   }
  // }, [window.customer, window.customerId]);

  return (
    <div className="h-screen z-[-10]">
      <button
        ref={pageChangeButtonRef}
        onClick={() => {
          ReactPixel.grantConsent();
          ReactPixel.pageView();
          console.log("Concent Granted");
        }}
        className="absolute top-[-200px]"
      >
        Page Changed
      </button>
      {showCookiePopup && (
        <div className="fixed bottom-0 left-0 w-screen h-[100px] bg-gray-200 flex justify-between px-[30px] items-center z-[999999] border-black border-t-2">
          <h1 className="text-3xl">
            We use cookies to provide the best experience for you.
          </h1>
          <button
            className="bg-white rounded-lg py-4 px-8 text-2xl"
            onClick={() => {
              ReactPixel.grantConsent();
              setShowCookiePopup(false);
              localStorage.setItem("cookies", "true");
            }}
          >
            Accept Cookies
          </button>
        </div>
      )}
      <>
        {showSideBar && (
          <>
            <Header toggleSidebar={toggleSidebar} loading={loading} />
            {width > parseFloat(1280) ? (
              <Sidebar
                toggleSidebar={toggleSidebar}
                loading={loading}
                selected={selected}
                setSelected={setSelected}
                customer={window.customer}
              />
            ) : (
              openSidebar && (
                <Sidebar
                  toggleSidebar={toggleSidebar}
                  loading={loading}
                  selected={selected}
                  setSelected={setSelected}
                  customer={window.customer}
                />
              )
            )}
          </>
        )}
      </>

      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          path="/pro"
          element={<ProHomePage setShowSideBar={setShowSideBar} />}
        />
        <Route
          path="/signIn"
          element={<SignInPage setShowSideBar={setShowSideBar} />}
        />
        <Route path="/invalid" element={<InvalidForm />} />

        <Route
          path="/dashboard"
          element={
            <Home
              eventPublication={eventPublication}
              loading={loading}
              setLoading={setLoading}
            />
          }
        />
        <Route
          path="/vetrina"
          element={<Vetrina loading={loading} setLoading={setLoading} />}
        />
        <Route
          path="/eventi"
          element={
            <Eventi
              eventPublication={eventPublication}
              loading={loading}
              setLoading={setLoading}
            />
          }
        />

        <Route path="/delete-data" element={<DeleteData />} />
        <Route
          path="/eventi/create"
          element={<Create loading={loading} setLoading={setLoading} />}
        />

        <Route path="/payment" element={<PaymentPage refresh={refresh} />} />
      </Routes>
    </div>
  );
}

export default App;
