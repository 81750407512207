import React from "react";
import { Row, Col } from "antd";
import "./style2.css";
import { CheckCircleOutlined } from "@ant-design/icons";
import Bg from "../../images/Tracciato 542.png";
function Component2() {
  return (
    <>
      <img className="bgposss" src={Bg} alt="" />
      <div>
        <div className="headtextdiv">
          <h1 className="headtext00">Scegli un Pacchetto!</h1>;
        </div>
        <Row justify="center">
          <Col lg={8} md={10} xs={24}>
            <div className="centerdiv22">
              <div className="card1">
                <div className="cornerdiv">
                  <div className="cardindindiv rounded-pill">BASE</div>
                </div>
                <h1 className="cardhead">Gratis</h1>
                <h6 className="cardpara">
                  Permette di mostrare il proprio esercizio <br />
                  commerciale su InfoIschia
                </h6>
                <a style={{ textDecoration: "none" }} href="#form">
                  <div className="buttondiv">
                    <button className="cardbutton">Iscriviti</button>
                  </div>
                </a>

                <div>
                  <p className="tt1">Include</p>
                  <div className="tt22 mt-4">
                    <CheckCircleOutlined className="check" />
                    <p className="tt21 ml-4">Vetrina con nome del locale</p>
                  </div>
                  <div className="tt22">
                    <CheckCircleOutlined className="check" />
                    <p className="tt222">Luogo e indicazioni per gli utenti</p>
                  </div>
                  <div className="tt22">
                    <CheckCircleOutlined className="check" />
                    <p className="tt23">Gestionale base</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} md={10} xs={24}>
            <div className="centerdiv22">
              <div>
                <div className="card1">
                  <div className="cornerdiv">
                    <div className="cardindindiv2 rounded-pill">Pro</div>
                  </div>
                  <h1 className="cardhead">100€</h1>
                  <h6 className="cardpara">
                    Offre una presenza su InfoIschia, con possibilità
                    <br /> di prenotazione e gestionale
                    <a className="cardpara1"> Pro</a>
                  </h6>
                  <a style={{ textDecoration: "none" }} href="#form">
                    <div className="buttondiv">
                      <button className="cardbutton2">Iscriviti</button>
                    </div>
                  </a>
                  <div>
                    <p className="tt1">Include Base più</p>
                    <div className="tt22 mt-4">
                      <CheckCircleOutlined className="check" />
                      <p className="tt21 ml-4">Immagini</p>
                    </div>
                    <div className="tt22">
                      <CheckCircleOutlined className="check" />
                      <p className="tt222">
                        Recapiti telefonici più link ai social
                      </p>
                    </div>
                    <div className="tt22">
                      <CheckCircleOutlined className="check" />
                      <p className="tt23">Link esterni al sito o menu etc…</p>
                    </div>
                    <div className="tt22">
                      <CheckCircleOutlined className="check" />
                      <p className="tt23">
                        Possibilità di pubblicare 1 evento al mese
                      </p>
                    </div>
                    <div className="tt22">
                      <CheckCircleOutlined className="check" />
                      <p className="tt23">
                        Gestionale Pro, con statistiche su tempo medio
                        <br />
                        Visualizzazioni, click e condivisioni
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Component2;
