import React from "react";
import InviaEmail from "../components/compo-third";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

function InvalidForm() {
  return (
    <div>
      <Helmet>
        <title>Infoischia - Invalid Form</title>
        <link rel="stylesheet" href="/landing.css" />
      </Helmet>
      <InviaEmail />
      <Footer />
    </div>
  );
}

export default InvalidForm;
