import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import RecentActivitiesCard from "./RecentActivitiesard/RecentActivitiesCard";

const RecentActivities = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // getDoc(collection(db, "Customers", window.customerId)).then((snapshot) => {
    const q = query(
      collection(db, "Customers", window.customerId, "recentActivities"),
      orderBy("timestamp", "desc")
    );
    getDocs(q).then((newSnapshot) => {
      const temp = [];
      newSnapshot.docs.forEach((document) => {
        const obj = {
          text: document.data()?.text,
          date: new Date(
            document.data()?.timestamp.toDate()
          ).toLocaleDateString(),
          timestamp: new Date(
            document.data()?.timestamp.toMillis()
          ).toLocaleTimeString("en-US", {
            hourCycle: "h24",
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        temp.push(obj);
      });

      setData(temp);
    });
  }, []);
  return (
    <div
      id="hidescrollbar"
      className="bg-white rounded-3xl md:rounded-[36px] shadow-2xl py-[6px] md:py-[20px] pl-[9px] md:pl-[30px] pr-[12px] md:pr-[40px] h-[300px] tablet:h-[200px] mobile:h-[200px] overflow-y-scroll flex flex-col"
    >
      <span className="text-customBlue font-poppins font-bold text-lg tablet:m-2 mobile:m-2 leading-[12px] md:leading-[40px]">
        Attività Recenti
      </span>
      <div className="flex flex-col gap-[4px]">
        {data.map((item, index) => (
          <RecentActivitiesCard
            key={index}
            text={item.text}
            date={item.date}
            timestamp={item.timestamp}
          />
        ))}
      </div>
    </div>
  );
};

export default RecentActivities;
