import React, { useState } from "react";
import FileIcon from "../../images/file.png";
import RequiredStar from "../RequiredStar";
import { HiLockClosed } from "react-icons/hi";

const DragZone = ({ images, setImages, isRed = false, onDelete }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // const filesToUpload = Array.prototype.slice.call(e.dataTransfer.files);
      const filesToUpload = e.dataTransfer.files;
      setImages([...images, ...filesToUpload]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const filesToUpload = Array.prototype.slice.call(e.target.files);
      setImages([...images, ...filesToUpload]);
    }
  };

  return (
    <>
      <form
        id="form-file-upload"
        className="relative text-center rounded-[30px] bg-lightGray"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        // style={{ opacity: window.customer.licenseType === 0 ? 0.4 : 1 }}
      >
        <RequiredStar
          top={15}
          left={15}
          isRed={window.customer.licenseType !== 0 && isRed}
        />
        <input
          type="file"
          id="input-file-upload"
          className="hidden"
          multiple={true}
          onChange={handleChange}
          accept="image/png, image/jpeg, image/jpg"
          value=""
          disabled={window.customer.licenseType === 0}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          style={{
            border:
              window.customer.licenseType !== 0 && isRed && "2px red solid",
          }}
          className={`${
            dragActive ? "drag-active" : ""
          } flex h-full w-full items-center justify-center border-[3px] border-dashed border-lightPurple rounded-[30px]`}
        >
          <div className="mt-[80px] flex flex-col items-center px-[22px]">
            {window.customer.licenseType !== 0 && (
              <div
                className={`flex flex-col items-center ${
                  images.length !== 0 && "opacity-0"
                }`}
              >
                <img src={FileIcon} alt="drag" className="mb-[12px]" />
                <span className="text-black1 font-poppins font-normal text-[20px] md:text-[30px] leading-[30px] md:leading-[40px] mb-[10px] md:mb-[5px]">
                  Seleziona dalla Galleria o{" "}
                  <span className="text-lightPurple">dai File</span>
                </span>
                <span className="text-[20px] leading-[30px] font-medium font-poppins text-black3 ">
                  Solo Immagini in formato PNG
                </span>
                <span className="font-poppins font-bold text-black3 text-[20px] leading-[30px] mb-[79px]">
                  Max 5 MB
                </span>
              </div>
            )}
            {window.customer.licenseType === 0 ? (
              <div className="w-full h-[300px]">
                <HiLockClosed
                  className="absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-[40%] text-[128px] text-customBlue"
                  style={{ opacity: 1, zIndex: 10 }}
                />
                <h1 className="absolute top-[240px] left-1/2 -translate-x-1/2 font-bold text-customBlue text-xl text-center w-full">
                  Per sbloccare le funzionalità di{" "}
                  <span className="text-customPink">Info</span>Ischia{" "}
                  <span className="text-sm text-customPink">Pro</span>, acquista
                  la licenza Premium
                </h1>
              </div>
            ) : (
              <div className="flex items-center space-x-4 w-full justify-start mb-[10px] flex-wrap">
                {images.map?.((image, index) => (
                  <>
                    <img
                      src={
                        typeof image === "string"
                          ? image
                          : URL.createObjectURL(image)
                      }
                      alt="uploaded"
                      key={index}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        images.splice(index, 1);
                        onDelete(index);
                        setImages(images);
                      }}
                      className={`${
                        index === 0
                          ? "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-[95%] w-[95%] z-0"
                          : "h-[50px] w-[50px] z-10"
                      } object-contain`}
                    />
                  </>
                ))}
              </div>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            className="absolute w-full h-full rounded-sm top-0 left-0 right-0 bottom-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <h1 className="text-center mt-2 text-lightPurple mb-[-10px]">
        Per rimuovere le immagini utilizza il tasto destro
      </h1>
      <h1 className="text-center mt-2 text-lightPurple">
        sulle immagini da rimuovere
      </h1>
    </>
  );
};

export default DragZone;
