import React, { useEffect, useState } from "react";
import EngagementOptions from "../EngagementOptions/EngagementOptions";
import ViewIcon from "../../../images/viewBlue.png";
import ShareIcon from "../../../images/shareBlue.png";
import { collection, getDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link, useNavigate } from "react-router-dom";

const EngagementOptionCard = ({ monthNumber }) => {
  const [statsType1, setStatsType1] = useState(0);
  const [statsType7, setStatsType7] = useState(0);

  useEffect(() => {
    if (window.customer.shopId) {
      getDocs(
        collection(
          db,
          window.customer.shopCategory,
          window.customer.shopId,
          "Statistics"
        )
      ).then((allStatSnapshot) => {
        let stats1 = 0;
        let stats7 = 0;
        allStatSnapshot.docs.forEach((doc) => {
          if (
            doc.data().statsType === 1 &&
            new Date(doc.data().timestamp.toDate()).getMonth() === monthNumber
          ) {
            stats1 += 1;
          }

          if (
            doc.data().statsType === 7 &&
            new Date(doc.data().timestamp.toDate()).getMonth() === monthNumber
          ) {
            stats7 += 1;
          }
        });

        setStatsType1(stats1);
        setStatsType7(stats7);
      });
    }
  }, [monthNumber]);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="flex items-center space-x-[15px] mb-[11px]">
        <div className="basis-1/2">
          <EngagementOptions
            image={ViewIcon}
            text={statsType1}
            subText="Views totali"
          />
        </div>

        <div className="basis-1/2">
          <EngagementOptions
            image={ShareIcon}
            text={statsType7}
            subText="Condivisioni"
          />
        </div>
      </div>
      <button
        onClick={() => navigate("/vetrina")}
        disabled={window.customer.licenseType === -1}
        className="bg-customBlue text-white py-4 rounded-2xl font-bold text-xl mx-4 disabled:opacity-40"
      >
        Crea una Vetrina
      </button>
    </div>
  );
};

export default EngagementOptionCard;
