// eslint-disable-next-line import/no-anonymous-default-export
export default function (timestamp) {
  timestamp = new Date(timestamp.seconds * 1000);
  return `${
    timestamp.getDate() +
    "/" +
    Number(timestamp.getMonth() + 1) +
    "/" +
    timestamp.getFullYear() +
    " alle " +
    (timestamp.getHours() <= 9 ? "0" : "") +
    timestamp.getHours() +
    ":" +
    (timestamp.getMinutes() <= 9 ? "0" : "") +
    timestamp.getMinutes()
  }`;
}
