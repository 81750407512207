import { useNavigate } from "react-router-dom";
import Logo from "../../images/arr.png";
import "./style.css";

function Navbar22() {
  const navigate = useNavigate();

  return (
    <div className="navbarcon flex justify-between">
      <div href="#home" className="divinfo">
        <p className="infotext">Info</p>
        <p className="infotext1">Ischia</p>
      </div>
      {/* <Navbar.Toggle /> */}
      <div className="">
        <div
          style={{ textDecoration: "none" }}
          onClick={() => navigate("/pro")}
        >
          <div className="divun">
            <p className="untext"> Hai un’attività?</p>
            {/* <img className="imglog img-fluid" src={Logo} alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar22;
