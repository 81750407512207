import { useEffect, useState } from "react";
import EngagementCard from "../components/EngagementCard/EngagementCard";
import InfoCard from "../components/InfoCard/InfoCard";
import RecentActivities from "../components/RecentActivities/RecentActivities";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function Home({ loading, setLoading, eventPublication }) {
  const [error, setError] = useState("");

  const getCustomer = async () => {
    try {
      const document = await getDoc(doc(db, "Customers", window.customerId));
      if (document.exists()) {
        window.customer = document.data();
        localStorage.setItem("customer", JSON.stringify(window.customer));
        sessionStorage.setItem("customer", JSON.stringify(window.customer));
      } else throw new Error("No Customer found with the matching id.");
    } catch (err) {
      console.error("Error: " + err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Infoischia | Dashboard";
    const localCustomerId =
      localStorage.getItem("customerId") ||
      sessionStorage.getItem("customerId");
    const localCustomer =
      localStorage.getItem("customer") || sessionStorage.getItem("customer");

    console.log({ localCustomer, localCustomerId });

    if (localCustomerId !== null) window.customerId = localCustomerId;
    if (localCustomer !== null) window.customer = JSON.parse(localCustomer);

    if (!window.customerId) return window.location.replace("/signIn");
    if (window.customerId) getCustomer();
  }, []);

  if (error) {
    return <>{error}</>;
  }
  if (!loading) {
    return (
      <div className="bg-bgColor">
        <div className="flex flex-col md:flex-row md:space-x-[40px] h-full md:ml-[288px] md:pt-[53px] md:mr-[90px] md:mb-[40px]">
          <div className="flex-col items-center my-4 mb-[-45px] justify-center hidden mobile:flex tablet:flex">
            <h6 className="text-lightPurple">Password</h6>
            <h1 className="text-lightPurple font-bold">
              {window?.customer?.password}
            </h1>
          </div>
          <div className="px-[16px] pt-[59px] pb-[25px] md:px-0 md:pt-0 md:pb-0 md:basis-2/3">
            <InfoCard eventPublication={eventPublication} />
          </div>
          <div className="px-[15px] pb-[25px] md:px-0 md:pt-0 md:pb-0 md:basis-1/3">
            <EngagementCard />
          </div>
        </div>

        <div className="px-[15px] pb-[105px] bg-bgColor md:ml-[288px] md:px-0 md:mr-[92px]">
          <RecentActivities />
        </div>
      </div>
    );
  } else {
    return <>Loading Customer Data...</>;
  }
}

export default Home;
