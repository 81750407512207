import React from "react";
import { Col, Row, Rate } from "antd";
import "./style.css";
import Logo1 from "../../images/rajapaksha.png";
import Home from "../../images/home.png";
import Home2 from "../../images/circ.png";
import { Apple, GooglePlay } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
function CompoFirst() {
  return (
    <div className="maincompofirt ">
      <Row justify="space-between">
        <Col lg={12} md={20} xs={24}>
          <div className="divcenter">
            <div>
              <h1 className="texttut">Tutta Ischia in un’app!</h1>
              <p className="textlor">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing
                <br /> elit. Phasellus id vulputate nunc. Pellentesque habitant
                <br /> morbi tristique senectus et netus */}
                Semplice, veloce, intuitiva:
                <br />
                InfoIschia è la guida pratica e funzionale dell’isola
                <br />
                d’Ischia!
              </p>

              <div className="maindivdis">
                <button
                  className="divdis"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/it/app/infoischia/id6444228299",
                      "_blank"
                    )
                  }
                >
                  <Apple size={36} />
                  <div className="flex flex-col gap-4">
                    <p className="textdis">Disponibile su</p>
                    <p className="textdis2">App Store</p>
                  </div>
                </button>
                <button
                  className="divdis22"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.app.infoischia",
                      "_blank"
                    )
                  }
                >
                  <GooglePlay size={36} />
                  <div className="flex flex-col gap-4">
                    <p className="textdis">Disponibile su</p>
                    <p className="textdis2">Play Store</p>
                  </div>
                </button>
              </div>
              {window.innerWidth >= 840 ? (
                <Link style={{ textDecoration: "none" }} to="/pro">
                  <p className="texthai">Hai un'attività ad Ischia?</p>
                </Link>
              ) : (
                <Link style={{ textDecoration: "none" }} to="/pro">
                  <p className="texthai">Hai un’attività as Ischia?</p>
                </Link>
              )}
            </div>
            <div className="div4kstar">
              <p className="text4k">4k utenti soddisfatti!</p>
              <Rate value={5} className="text4k22" disabled />
            </div>
          </div>
        </Col>
        <Col lg={8} md={20} xs={24}>
          <div className="divcenter22">
            <img className=" hom2img99" src={Logo1} alt="" />
            <img className="img-fluid homimg" src={Home} alt="" />
            <img className="img-fluid mbhome2" src={Home2} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CompoFirst;
