import React from "react";
import CompoFirst from "../components/compo-One";
import CompoTwo from "../components/compo-Two";
import Footer from "../components/footer";
import Navbar22 from "../components/navbar";
import { Helmet } from "react-helmet";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

function HomePage() {
  return (
    <div>
      <Helmet>
        <title>Infoischia</title>
        <link rel="stylesheet" href="/landing.css" />
      </Helmet>
      <Navbar22 />
      <CompoFirst />
      <CompoTwo />
      <Footer />
    </div>
  );
}

export default HomePage;
