import React from "react";
import { Col, Row } from "antd";
import "./style.css";
import Computer from "../../images/Group 1000002457.png";
import { Link } from "react-router-dom";
function InviaEmail() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [isFormValid, setIsFormValid] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkFormValidity = () => {
    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      businessName !== "" &&
      description !== ""
    )
      return setIsFormValid(true);
    else return setIsFormValid(false);
    // if (!name || !email || !phone || !businessName || !description)
    //   return setIsFormValid(false);
  };

  React.useEffect(() => {
    checkFormValidity();
  }, [name, email, phone, businessName, description, checkFormValidity]);

  return (
    <div className="min-h-screen">
      <div>
        <Row
          justify="space-evenly "
          className="flex justify-between tablet:justify-center mobile:justify-center tablet:flex-col-reverse mobile:flex-col-reverse px-[120px] tablet:px-[4px] mobile:px-[4px]"
        >
          <Col lg={10}>
            <div className="centerinvalid33">
              <div className="textdivti">
                <h1 className="textti">
                  Ti aiuta<a className="textti23 ml-4">Info</a>
                  <a>Ischia</a> <a className="textti23">Pro</a>
                </h1>
              </div>
              <p className="mbl">
                Recupera su<a className="mbl2 ml-3">Info</a>Ischia
                <a className="mbl2"> Pro</a>
              </p>
              <p className="mbl333">
                Inserisci queste informazioni, verrai contattato
                <br /> con nuove credenziali
              </p>
              <p className="textti2">
                Inserisci queste informazioni, verrai contattato
                <br /> dal team
              </p>
              <img className="img-fluid imgcom" src={Computer} alt="" />
            </div>
          </Col>
          <Col lg={10}>
            <div className="centerinvalid">
              <div className="centerinvalid22">
                <input
                  type="text"
                  placeholder="Nome e Cognome Referente"
                  className="inputin"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  placeholder="Email"
                  className="inputin"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  placeholder="Numero di Telefono Referente"
                  className="inputin"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  placeholder="Nome dell’attività su InfoIschia"
                  className="inputin"
                  name="businessName"
                  onChange={(e) => setBusinessName(e.target.value)}
                />
                <textarea
                  placeholder="Descrizione dell’attività e richiesta"
                  className="inputin22"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              {/* <Link style={{ textDecoration: "none" }} to="/signIn"> */}
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href={`mailto:supporto@infoischia.com?subject=${name} - ${businessName} ${phone}&body=${description}`}
                target={"_blank"}
                rel="noreferrer"
              >
                <button className="btninvia" disabled={!isFormValid}>
                  Invia Email
                </button>
              </a>
              {/* </Link> */}
              <Link style={{ textDecoration: "none" }} to="/signIn">
                <p className="textacce">
                  Accedi a <a className="textacce1">Info</a>Ischia
                  <a className="textacce1">Pro</a>
                </p>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default InviaEmail;
