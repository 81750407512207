import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

export default function DeleteData() {
  return (
    <>
      <Helmet>
        <title>Infoischia - Delete Data</title>
        <link rel="stylesheet" href="/landing.css" />
      </Helmet>
      <div className="min-h-screen min-w-screen relative p-0">
        <div className="absolute top-[-25px] left-[10px]">
          <div className="centerfooter22">
            <p className="infotext22">Info</p>
            <p className="infotext122">Ischia</p>
          </div>
        </div>
        <div className="text-center text-6xl font-extrabold absolute top-[200px] text-customBlue left-1/2 -translate-x-1/2 w-full">
          Benvenuto nella pagina di eliminazione del profilo
        </div>
        <p className="text-center absolute top-[300px] left-1/2 -translate-x-1/2 text-3xl text-gray-400">
          Per eliminare il tuo profilo da Infolschia bisogna andare nelle
          impostazioni cliccare su{" "}
          <span className="text-customBlue">Elimina il tuo Account</span> e
          confermare l'eliminazione. Attenzione tutti i dati del tuo profilo
          verranno eliminati e non potrai accederci più.
        </p>
      </div>
      <Footer />
    </>
  );
}
