import React, { useEffect, useState, useRef } from "react";
import DragZone from "../components/DragZone/DragZone";
import BigPhoneIcon from "../images/bigPhone.png";
import NameFieldIcon from "../images/nameFieldIcon.png";
import DescriptionFieldIcon from "../images/textDashedGray.png";
import LocationIcon from "../images/locationGray.png";
import ClockIcon from "../images/clockGray.png";
import PhoneIcon from "../images/phoneGray.png";
import ApplicationIcon1 from "../images/application1Gray.png";
import ApplicationIcon2 from "../images/application2Gray.png";
import TextIcon from "../images/textIcon.png";
import PrincipalFieldIcon from "../images/principalFieldIcon.png";
import InstagramIcon from "../images/instagramGray.png";
import FacebookIcon from "../images/facebookGray.png";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, GeoPoint, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import LocationSelector from "../components/LocationSelector";
import { Navigate, useNavigate } from "react-router-dom";
import RequiredStar from "../components/RequiredStar";
import EmailIcon from "../images/email.svg";
import formatDate from "../utils/formatDate";
import LoadingIcon from "../components/LoadingIcon";
import imageCompression from "browser-image-compression";
import { HiLockClosed } from "react-icons/hi";

const Cats = {
  Restaurants: [
    { text: "Tipi di attività", heading: true },
    { text: "Ristoranti" },
    { text: "Pizzerie" },
    { text: "Trattorie" },
    { text: "Bar e caffè" },
    { text: "Gelaterie" },
    { text: "Creperie" },
    { text: "Pasticcerie" },
    { text: "Yogurteria" },
    { text: "Snack" },
    { text: "Consegne a domicilio" },
    { text: "Cene" },
    { text: "Disco bar" },
    { text: "Discoteche" },
    { text: "Bar per aperitivi" },
    { text: "Piano bar" },
    { text: "Enoteche e wine bar" },
    { text: "Pub" },
    { text: "Gastronomia e catering" },
    { text: "Prodotti Tipici Locali" },
    { text: "Minimarket e supermercati" },
    { text: "Panifici" },
    { text: "Pescherie" },
    { text: "Macellerie" },

    { text: "Tipi di cucina", heading: true },
    { text: "Italiana" },
    { text: "Mediterranea" },
    { text: "Asiatico" },
    { text: "Pizzeria" },
    { text: "Di Pesce" },
    { text: "Paninoteca" },
    { text: "Giapponese" },
    { text: "Di Carne" },
    { text: "Internazionale" },
    { text: "Cinese" },
    { text: "Cucina locale" },
    { text: "Fusion" },
    { text: "Orientale" },
    { text: "Americano" },
    { text: "Steakhouse" },
    { text: "Vegano" },
    { text: "Vegetariano" },

    { text: "Stili e occasioni", heading: true },
    { text: "Stellato" },
    { text: "Gourmet" },
    { text: "Romantico" },
    { text: "Sul mare " },
    { text: "In montagna " },
    { text: "Famiglie" },
    { text: "Tra amici" },
    { text: "Per lavoro" },
    { text: "Enoteca" },
    { text: "All’aperto" },
    { text: "Bistrot" },
    { text: "Panoramico" },
    { text: "Brunch" },
    { text: "Osteria" },
    { text: "Healthy" },
    { text: "Spazio Bimbi" },
    { text: "Pub e birreria " },
    { text: "Agriturismo" },

    { text: "Piatti", heading: true },
    { text: "Pesce e frutti di mare" },
    { text: "Carne e Grigliate" },
    { text: "Pizza" },
    { text: "Hamburger" },
    { text: "Pasta fresca" },
    { text: "Fiorentina" },
    { text: "Insalate" },
    { text: "Pollo allo spiedo" },
    { text: "Poke bowl" },
    { text: "Tartare" },
    { text: "Tagliere di Salumi" },

    { text: "Esigenze particolari", heading: true },
    { text: "Piatti biologici" },
    { text: "Opzioni senza glutine" },
    { text: "Piatti vegani" },
    { text: "Piatti vegetariani" },
    { text: "Km 0" },

    { text: "Servizi", heading: true },
    { text: "Animali ammessi" },
    { text: "Wi-Fi" },
    { text: "Menu bambini" },
    { text: "Partite di calcio" },
  ],

  Shopping: [
    { text: "Tipi di attività", heading: true },

    { text: "Abbigliamento da donna" },
    { text: "Abbigliamento da uomo" },
    { text: "Attrezzatura sportiva" },
    { text: "Accessori moda" },
    { text: "Calzature" },
    { text: "Abbigliamento Bambini" },
    { text: "Intimo e abbigliamento da mare" },
    { text: "Gioiellerie" },
    { text: "Profumi e trucco" },
    { text: "Ottici e fotografi" },
    { text: "Tabaccherie e varie" },
    { text: "Cellulari" },
    { text: "Computer ed elettrodomestici" },
    { text: "Prodotti Locali" },
    { text: "Piante e fiori" },
    { text: "Cartolerie" },
    { text: "Cosmetici" },
    { text: "Giocattoli" },
    { text: "Librerie" },
    { text: "Souvenir" },
    { text: "Tutto per la casa" },
    { text: "Beni per la casa" },
    { text: "Hardware" },
    { text: "Illuminazione" },
    { text: "Videogiochi e console" },
    { text: "Noleggio di film" },
    { text: "Farmacie" },
    { text: "Matrimoni" },
    { text: "Cantine" },
    { text: "Vendita Auto" },
    { text: "Vendita Moto" },
    { text: "Vendita Barche" },
    { text: "Accessori Nautici" },
    { text: "Pesca" },
    { text: "Altri Shopping" },
  ],

  Hotels: [
    { text: "Stelle", heading: true },

    { text: "2 stelle" },
    { text: "3 stelle" },
    { text: "4 stelle" },
    { text: "5 stelle" },

    { text: "Tipologia di struttura", heading: true },

    { text: "Case e appartamenti" },
    { text: "Hotel" },
    { text: "Bed & Breakfast" },
    { text: "Ville" },
    { text: "Ostelli" },

    { text: "Servizi", heading: true },

    { text: "Navetta" },
    { text: "Animali ammessi" },
    { text: "Connessione Wi-Fi" },
    { text: "Camere non fumatori" },
    { text: "Parcheggio" },
    { text: "Ristorante" },
    { text: "Piscina" },
    { text: "Palestra" },
    { text: "Spa & Centro benessere" },
    { text: "Camere per disabili" },
    { text: "Spiaggia" },
    { text: "Colonnine di ricarica elettriche" },

    { text: "Zone", heading: true },

    { text: "Barano d'Ischia" },
    { text: "Casamicciola Terme" },
    { text: "Forio d'Ischia" },
    { text: "Ischia" },
    { text: "Lacco Ameno" },
    { text: "Serrara Fontana" },
  ],

  Rent: [
    { text: "Tipologia di noleggio", heading: true },

    { text: "Noleggio auto" },
    { text: "Noleggio scooter" },
    { text: "Noleggio barche" },
    { text: "Noleggio bici" },
    { text: "Distributori Carburanti 24h" },
    { text: "Autolavaggi" },
    { text: "Gommisti" },
    { text: "Punti di Ormeggio" },
    { text: "Escursioni" },
    { text: "Transfer privati" },
    { text: "Cinema" },
    { text: "Palestre" },
    { text: "Equitazione" },
    { text: "Svaghi" },
    { text: "Campi Tennis" },
    { text: "Campi Calcetto" },
    { text: "Scommesse" },
    { text: "Bancomat" },
    { text: "Banche" },
    { text: "Poste" },
    { text: "Assicurazioni" },
    { text: "Parcheggi" },
    { text: "Agenzie Viaggio" },
    { text: "Lavanderie" },
    { text: "Organizzazione Eventi" },
    { text: "Spedizioni" },
    { text: "Grafica e Pubblicità" },
    { text: "Intrattenimento Bambini" },
    { text: "Farmacie" },
    { text: "Parafarmacie" },
    { text: "Guardia Medica" },
    { text: "Guardia Medica Turistica" },
    { text: "Dentisti" },
    { text: "Veterinari" },
    { text: "Ospedale" },
    { text: "Pronto Soccorso" },
    { text: "Fisioterapia e Riabilitazione" },
    { text: "Wedding" },
    { text: "Noleggio Audio & Video" },
    { text: "Soccorso Stradale" },
    { text: "Polizia Locale" },
    { text: "Colonnine di ricarica elettriche" },
    { text: "Alimentari & Supermercati" },
    { text: "Altri Servizi" },
  ],

  Wellbeing: [
    { text: "Tipologia di attività", heading: true },

    { text: "Parchi termali" },
    { text: "Parrucchieri donne" },
    { text: "Parrucchieri uomo" },
    { text: "Centri estetici" },
    { text: "Spa & Terme" },
    { text: "Centri di abbronzatura" },
    { text: "Tatuaggi" },
    { text: "Palestre" },
  ],

  Culture: [
    { text: "Zone", heading: true },

    { text: "Barano d'Ischia" },
    { text: "Casamicciola Terme" },
    { text: "Forio d'Ischia" },
    { text: "Ischia" },
    { text: "Lacco Ameno" },
    { text: "Serrara Fontana" },
  ],
};
const mainButtonActionType = [
  "Prenota ora",
  "Chiama ora",
  "Seguici su Instagram",
  "Seguici su Facebook",
  "Connessione al primo link principale",
  "Connessione al secondo link principale",
  "Scrivici una mail",
];

const cateToImages = {
  Beach:
    "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-beach-image.png?alt=media&token=37ca7a74-7b15-484c-8ee6-be243d02821d",

  Restaurants:
    "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-food%26drink-image.png?alt=media&token=0bb827be-f430-4d44-9c02-a85d1322609a",

  Hotels:
    "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-hotel-image.png?alt=media&token=6f9f1c7c-c946-4d60-b92c-59970a178576",

  Rent: "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-rent-image.png?alt=media&token=2c0e8adb-f73b-4eac-acc8-7ef2b2b2a2f6",

  Shopping:
    "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-shopping-image.png?alt=media&token=1ebfef45-36c5-4e1a-942b-ef2d54f34a7a",

  Wellbeing:
    "https://firebasestorage.googleapis.com/v0/b/infoischia.appspot.com/o/default-wellbeing-image.png?alt=media&token=eb1e0401-2a64-42d7-b539-28761d3ec4fb",
};

const Vetrina = ({ loading, setLoading }) => {
  const [step, setStep] = useState(0);
  let [formData, setFormData] = useState({
    email: { en: "", it: "" },
    address: { en: "", it: "" },
    addressGeopoint: new GeoPoint(40.72846578226386, 13.902980132948867),
    description: { en: "", it: "" },
    facebookLink: { en: "", it: "" },
    firstLink: { en: "", it: "" },
    firstLinkDescription: { en: "", it: "" },
    firstLinkName: { en: "", it: "" },
    instagramLink: { en: "", it: "" },
    mainButtonActionType: { en: "", it: "" },
    mainButtonText: { en: "", it: "" },
    openingHour: {
      mon: { open: "", close: "", open2: "", close2: "" },
      tue: { open: "", close: "", open2: "", close2: "" },
      wed: { close: "", open: "", close2: "", open2: "" },
      thu: { close: "", open: "", close2: "", open2: "" },
      fri: { open: "", close: "", open2: "", close2: "" },
      sat: { close: "", open: "", close2: "", open2: "" },
      sun: { close: "", open: "", close2: "", open2: "" },
    },
    phoneNumber: { en: "0", it: "0" },
    secondLink: { en: "", it: "" },
    secondLinkDescription: { en: "", it: "" },
    secondLinkName: { en: "", it: "" },
    emailToReceive: { en: "", it: "" },
    shopName: "",
    shopTag: [],
    imageUrls: { it: [], en: [] },
    lastSentTimestamp: new Date(),
  });
  const navigate = useNavigate();

  const [openLocationSelector, setOpenLocationSelector] = useState(false);
  const [location, setLocation] = useState({ _lat: 0, _long: 0 });

  const [openTimeSelector, setOpenTimeSelector] = useState(false);
  const [openingHours, setOpeningHours] = useState({});
  const [formLoading, setFormLoading] = useState(false);

  // const [loading, setLoading] = useState(true);

  const [locale, setLocale] = useState("it");
  const [refreshVal, setRefresh] = useState("it");

  const refresh = () => setRefresh(Math.random());

  let [selectedTags, setSelectedTags] = useState([]);

  const [formErrors, setFormErrors] = useState({
    shopNameError: "",
    descriptionError: "",
    phoneNumberError: "",
    general: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const mainButtonTextField = useRef(null);
  const mainButtonActionTypeRef = useRef(null);
  const shopCatSelectRef = useRef(null);

  const handleChange = (e) => {
    if (["shopName", "addressGeopoint"].includes(e.target.name)) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          [locale]: e.target.value,
        },
      });
    }
  };

  const handleSubmit = async () => {
    setFormErrors((errors) => validateForm());
    setIsSubmit(true);
  };

  const validateForm = () => {
    const error = {};
    if (formData.shopName.length === 0) {
      error.shopNameError = "Cannot be empty";
      return error;
    }

    if (
      window?.customer?.shopCategory !== "Culture" &&
      window.customer.licenseType !== 0
    ) {
      if (
        formData.phoneNumber.it.length <= 0 ||
        formData.phoneNumber.it === "0"
      ) {
        error.descriptionError = "Cannot be empty";
        return error;
      }

      if (
        formData.phoneNumber.en.length <= 0 ||
        formData.phoneNumber.en === "0"
      ) {
        error.phoneNumberError = "Phone Number cannot be empty (Inglese)";
        return error;
      }
      if (
        formData.phoneNumber.it.length <= 0 ||
        formData.phoneNumber.it === "0"
      ) {
        error.phoneNumberError = "Phone Number cannot be empty (Italiano)";
        return error;
      }
    }

    if (formData.address.it === "") {
      error.general = "No address (Italiano)";
      return error;
    }
    if (formData.address.en === "") {
      error.general = "No address (Inglese)";
      return error;
    }

    if (formData.description.it === "") {
      error.descriptionError = "No description (Italiano)";
      return error;
    }
    if (formData.description.en === "") {
      error.descriptionError = "No description (Inglese)";
      return error;
    }

    if (window.customer.licenseType !== 0) {
      if (formData.mainButtonText.it === "") {
        error.general = "No main button text (Italiano)";
        return error;
      }
      if (formData.mainButtonText.en === "") {
        error.general = "No main button text (Inglese)";
        return error;
      }

      if (formData.mainButtonActionType.it === "") {
        error.general = "No main button action type (Italiano)";
        return error;
      }
      if (formData.mainButtonActionType.en === "") {
        error.general = "No main button action type (Inglese)";
        return error;
      }

      if (
        formData.firstLink.it ||
        formData.firstLinkName.it ||
        formData.firstLinkDescription.it ||
        formData.mainButtonActionType.it === "4"
      ) {
        if (!formData.firstLink.it) {
          error.general = "No first link (Italiano)";
          return error;
        }
        if (!formData.firstLinkName.it) {
          error.general = "No first link name (Italiano)";
          return error;
        }
        if (!formData.firstLinkDescription.it) {
          error.general = "No first link description (Italiano)";
          return error;
        }
      }

      if (
        formData.secondLink.it ||
        formData.secondLinkName.it ||
        formData.secondLinkDescription.it ||
        formData.mainButtonActionType.it === "5"
      ) {
        if (!formData.secondLink.it) {
          error.general = "No second link (Italiano)";
          return error;
        }
        if (!formData.secondLinkName.it) {
          error.general = "No second link name (Italiano)";
          return error;
        }
        if (!formData.secondLinkDescription.it) {
          error.general = "No second link description (Italiano)";
          return error;
        }
      }

      if (
        formData.firstLink.en ||
        formData.firstLinkName.en ||
        formData.firstLinkDescription.en ||
        formData.mainButtonActionType.en === "4"
      ) {
        if (!formData.firstLink.en) {
          error.general = "No first link (Inglese)";
          return error;
        }
        if (!formData.firstLinkName.en) {
          error.general = "No first link name (Inglese)";
          return error;
        }
        if (!formData.firstLinkDescription.en) {
          error.general = "No first link description (Inglese)";
          return error;
        }
      }

      if (
        formData.secondLink.en ||
        formData.secondLinkName.en ||
        formData.secondLinkDescription.en ||
        formData.mainButtonActionType.en === "5"
      ) {
        if (!formData.secondLink.en) {
          error.general = "No second link (Inglese)";
          return error;
        }
        if (!formData.secondLinkName.en) {
          error.general = "No second link name (Inglese)";
          return error;
        }
        if (!formData.secondLinkDescription.en) {
          error.general = "No second link description (Inglese)";
          return error;
        }
      }
      if (formData.imageUrls.en.length === 0) {
        error.general = "Add Images (Inglese)";
        return error;
      }
      if (formData.imageUrls.it.length === 0) {
        error.general = "Add Images (Italiano)";
        return error;
      }

      if (
        formData.mainButtonText.it === "Prenota ora" &&
        formData.emailToReceive.it === ""
      ) {
        error.general = "Enter email to receive reservations.";
      }
      if (
        formData.mainButtonText.en === "Prenota ora" &&
        formData.emailToReceive.it === ""
      ) {
        error.general = "Enter email to receive reservations.";
      }

      if (
        formData.mainButtonText[locale] === "Seguici su Facebook" &&
        formData.facebookLink.it === ""
      ) {
        error.general = "Enter Facebook link (Italiano)";
      }
      if (
        formData.mainButtonText[locale] === "Seguici su Facebook" &&
        formData.facebookLink.en === ""
      ) {
        error.general = "Enter Facebook link (Inglese)";
      }
      if (
        formData.mainButtonText[locale] === "Seguici su Instagram" &&
        formData.instagramLink.it === ""
      ) {
        error.general = "Enter Instagram link (Italiano)";
      }
      if (
        formData.mainButtonText[locale] === "Seguici su Instagram" &&
        formData.instagramLink.en === ""
      ) {
        error.general = "Enter Instagram link (Inglese)";
      }
      if (
        formData.mainButtonText[locale] === "Scrivici una mail" &&
        formData.email.en === ""
      ) {
        error.general = "Enter email (Inglese)";
      }
      if (
        formData.mainButtonText[locale] === "Scrivici una mail" &&
        formData.email.it === ""
      ) {
        error.general = "Enter email (Italiano)";
      }
    }

    if (formData.shopTag.filter((n) => n).length === 0) {
      error.general = "Add some shop tags. (Inglese)";
      return error;
    }

    if (Math.round(formData.addressGeopoint._lat) === 0) {
      error.general = "Select location on the map.";
      return error;
    }

    if (
      !formData?.openingHour?.mon.open ||
      !formData?.openingHour?.tue.open ||
      !formData?.openingHour?.wed.open ||
      !formData?.openingHour?.thu.open ||
      !formData?.openingHour?.fri.open ||
      !formData?.openingHour?.sat.open ||
      !formData?.openingHour?.sun.open ||
      !formData?.openingHour?.mon.close ||
      !formData?.openingHour?.tue.close ||
      !formData?.openingHour?.wed.close ||
      !formData?.openingHour?.thu.close ||
      !formData?.openingHour?.fri.close ||
      !formData?.openingHour?.sat.close ||
      !formData?.openingHour?.sun.close
    ) {
      error.general = "Select opening hours.";
      return error;
    }

    return error;
  };

  const uploadData = async () => {
    setFormLoading(true);
    let index = 0;
    let itIndex = 0;
    let enIndex = 0;
    // let itUrls = [];
    // let enUrls = [];

    console.log(formData.addressGeopoint);

    let data = {
      windowRequest: 0,
      windowRequestMap: {
        ...window.customer.windowRequestMap,
        email: formData.email,
        address: formData.address,
        addressGeopoint: new GeoPoint(
          formData.addressGeopoint.lat || formData.addressGeopoint._lat,
          formData.addressGeopoint.lng || formData.addressGeopoint._long
        ),
        description: {
          it: formData.description.it.replace(/(?:\r\n|\r|\n)/g, "///n"),
          en: formData.description.en.replace(/(?:\r\n|\r|\n)/g, "///n"),
        },
        facebookLink: formData.facebookLink,
        firstLink: formData.firstLink,
        firstLinkName: formData.firstLinkName,
        firstLinkDescription: formData.firstLinkDescription,
        instagramLink: formData.instagramLink,
        mainButtonActionType: {
          it: Number(formData.mainButtonActionType.it),
          en: Number(formData.mainButtonActionType.en),
        },
        mainButtonText: formData.mainButtonText,
        openingHour: formData.openingHour,
        phoneNumber: {
          it: formData.phoneNumber.it || "0",
          en: formData.phoneNumber.en || "0",
        },
        secondLink: formData.secondLink,
        secondLinkName: formData.secondLinkName,
        secondLinkDescription: formData.secondLinkDescription,
        shopName: formData.shopName,
        shopTag: formData.shopTag.filter((n) => n),
        imageUrls: formData.imageUrls,
        lastSentTimestamp: new Date(),
        emailToReceive: formData.emailToReceive,
      },
    };

    const en = formData.imageUrls.en.map(async (image) => {
      if (typeof image === "string") return image;
      return await uploadImages(image);
    });
    const it = formData.imageUrls.it.map(async (image) => {
      if (typeof image === "string") return image;
      return await uploadImages(image);
    });
    // console.log(
    //   data.windowRequestMap.imageUrls.en,
    //   data.windowRequestMap.imageUrls.it
    // );

    const enImages = await Promise.all(en);
    const itImages = await Promise.all(it);

    if (window.customer.licenseType === 0) {
      data.windowRequestMap.imageUrls.en = [
        cateToImages[window.customer.shopCategory]
          ? cateToImages[window.customer.shopCategory]
          : cateToImages["Restaurants"],
      ];
      data.windowRequestMap.imageUrls.it = [
        cateToImages[window.customer.shopCategory]
          ? cateToImages[window.customer.shopCategory]
          : cateToImages["Restaurants"],
      ];
    } else {
      data.windowRequestMap.imageUrls.en = enImages;
      data.windowRequestMap.imageUrls.it = itImages;
    }
    // if (1) return console.log(data.windowRequestMap.imageUrls);
    console.log(data);
    await updateDoc(doc(db, "Customers", window.customerId), data).catch(
      (err) => {
        console.error(err);
      }
    );

    await getCustomer();
    setFormLoading(false);
  };

  // const uploadImages = (image) => {
  const uploadImages = async (image) => {
    // if (images.length > 0) {

    if (typeof image === "string") return image;
    let file = image;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substring(i, 2)).toString(36);
    }
    const fileName = "customerImage" + out + "-" + Math.random();
    const storageRef = ref(storage, "customerImages/" + fileName);
    console.log({
      file: file.size,
      compressedFile: compressedFile.size,
      difference: file.size - compressedFile.size,
    });
    const fileSnapshot = await uploadBytes(storageRef, compressedFile);
    const downloadURL = await getDownloadURL(fileSnapshot.ref);
    console.log(downloadURL);
    return downloadURL;
  };

  const getCustomer = async () => {
    try {
      const document = await getDoc(doc(db, "Customers", window.customerId));
      if (document.exists()) window.customer = document.data();
      else throw new Error("No Customer found with the matching id.");
      localStorage.setItem("customer", JSON.stringify(window.customer));
      refresh();
    } catch (err) {
      console.error("Error: " + err.message);
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };

  const startUp = async () => {
    const localCustomerId =
      localStorage.getItem("customerId") ||
      sessionStorage.getItem("customerId");
    const localCustomer =
      localStorage.getItem("customer") || sessionStorage.getItem("customer");

    if (localCustomerId !== null) window.customerId = localCustomerId;
    if (localCustomer !== null) window.customer = JSON.parse(localCustomer);

    if (!window.customerId) return window.location.replace("/signIn");
    if (!window.customer) await getCustomer();
    // setShowSideBar(true);

    getDoc(doc(db, "Customers", window.customerId))
      .then((document) => {
        const data = document.data()?.windowRequestMap;

        setFormData({
          addressGeopoint: {
            _lat: data?.addressGeopoint._lat,
            _long: data.addressGeopoint._long,
          },
          address: data?.address,
          shopName: data?.shopName,
          description: data?.description,
          phoneNumber: data?.phoneNumber,
          firstLink: data?.firstLink,
          firstLinkDescription: data?.firstLinkDescription,
          firstLinkName: data?.firstLinkName,
          secondLink: data?.secondLink,
          secondLinkDescription: data?.secondLinkDescription,
          secondLinkName: data?.secondLinkName,
          instagramLink: data?.instagramLink,
          facebookLink: data?.facebookLink,
          openingHour: data.openingHour,
          mainButtonActionType: data.mainButtonActionType,
          mainButtonText: data?.mainButtonText,
          shopTag: data?.shopTag,
          email: data?.email,
          imageUrls: { it: data.imageUrls.it, en: data.imageUrls.en },
          emailToReceive: {
            it: data.emailToReceive?.it || "",
            en: data.emailToReceive?.en || "",
          },
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      formErrors.constructor === Object &&
      isSubmit
    ) {
      uploadData();
    }
  }, [formErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    startUp();
  }, []);

  useEffect(() => {
    setFormData({ ...formData, addressGeopoint: location });
  }, [location]);

  const changeButtonActionType = (e) => {
    // if (mainButtonTextField.current) {
    const value = Number(e.target.value);
    if (value === 5 || value === 4) {
      mainButtonTextField.current.value = "";
      formData.mainButtonText[locale] = "";
      mainButtonTextField.current.disabled = false;
    } else if (value !== 4 || value !== 5) {
      mainButtonTextField.current.value = mainButtonActionType[value];
      formData.mainButtonText[locale] = mainButtonActionType[value];
      mainButtonTextField.current.disabled = true;
    }
    handleChange(e);
    // }
  };

  // useEffect(() => {
  //   setFormData({ ...formData, openingHour: openingHours });
  // }, [openingHours]);

  if (window?.customer?.licenseType === -1) {
    navigate("/dashboard");
    return <>Not Authenticated, Redirecting..</>;
  }
  if (loading) {
    return <>Loading...</>;
  }

  return (
    <div className="bg-bgColor">
      {openLocationSelector && (
        <LocationSelectorModel
          setLocation={setLocation}
          onClose={() => setOpenLocationSelector(false)}
          DefaultLocation={
            Math.round(formData.addressGeopoint._lat) === 0
              ? new GeoPoint(40.72846578226386, 13.902980132948867)
              : formData.addressGeopoint
          }
        />
      )}

      {openTimeSelector && (
        <TimeSelectorModel
          setOpeningHours={setOpeningHours}
          openingHours={
            formData.openingHour === {} ? openingHours : formData.openingHour
          }
          onClose={() => setOpenTimeSelector(false)}
          formData={formData}
          refresh={refresh}
          setFormData={setFormData}
        />
      )}
      {window.customer.windowRequest === 0 ? (
        <div className="bg-bgColor md:pt-[53px] md:pl-[288px] md:pr-[46px]">
          <div className="border-2 flex flex-col items-center justify-center border-customBlue px-40 rounded-xl py-4 mt-8">
            <span className="text-customBlue font-semibold text-xl text-center">
              {step === 1
                ? "Vetrina Approvata"
                : "Vetrina inviata in attesa di approvazione"}
            </span>
            <span className="text-center font-semibold text-gray-400">
              Inviata il{" "}
              {formatDate(window.customer.windowRequestMap.lastSentTimestamp)}
            </span>
            {window.customer.windowRequestMap?.lastApprovedTimestamp?.seconds &&
              step === 1 && (
                <span className="text-center font-semibold text-gray-400">
                  Approvata il{" "}
                  {formatDate(
                    window.customer.windowRequestMap.lastApprovedTimestamp
                  )}
                </span>
              )}
            <button
              className={`${
                step === 0 ? "bg-red-500" : "bg-customBlue"
              } text-white rounded-lg px-4 py-2 mt-2 w-[40%] font-semibold disabled:opacity-40`}
              onClick={async () => {
                if (step === 0) {
                  await updateDoc(doc(db, "Customers", window.customerId), {
                    windowRequest: -1,
                  });

                  localStorage.setItem(
                    "customer",
                    JSON.stringify({ ...window.customer, windowRequest: -1 })
                  );
                  if (
                    window.customer.windowRequestMap?.lastApprovedTimestamp
                      ?.seconds
                  ) {
                    setStep(1);
                  } else window.location.reload();
                }
                if (step === 1) {
                  window.location.reload();
                }
              }}
            >
              Annulla Invio
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-bgColor md:pt-[53px] md:pl-[288px] md:pr-[46px]">
            <div className="flex justify-center gap-4 mb-8">
              <button
                className={`relative w-full py-4 rounded-xl ${
                  locale === "it" && "bg-customBlue text-white"
                } font-semibold`}
                style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.25)" }}
                onClick={() => setLocale("it")}
              >
                <RequiredStar />
                Italiano
              </button>
              <button
                className={`relative w-full py-4 rounded-xl ${
                  locale === "en" && "bg-customBlue text-white"
                } font-semibold`}
                style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.25)" }}
                onClick={() => setLocale("en")}
              >
                <RequiredStar />
                Inglese
              </button>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col pt-[59px] px-[22px] mb-[15px] md:pt-0 md:px-0 md:basis-2/5">
                {/* {console.log(formData.imageUrls[locale])} */}
                <DragZone
                  images={
                    window.customer.windowRequest !== 0
                      ? formLoading
                        ? []
                        : formData.imageUrls[locale]
                      : []
                  }
                  setImages={(image) => {
                    console.log(image);
                    formData.imageUrls[locale] = image;
                    refresh();
                  }}
                  onDelete={(index) => {
                    formData.imageUrls[locale].splice(index, 1);
                  }}
                  isRed={
                    formData.imageUrls[locale].length === 0 &&
                    formErrors.general !== ""
                  }
                />
              </div>

              <div className="flex flex-col px-[22px] mb-[60px] md:basis-3/5">
                <div
                  style={{
                    border:
                      formErrors.shopNameError !== "" &&
                      formData.shopName === "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    <RequiredStar
                      isRed={
                        formErrors.shopNameError !== "" &&
                        formData.shopName === ""
                      }
                    />
                    <img
                      src={NameFieldIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    name="shopName"
                    value={formData.shopName}
                    onChange={handleChange}
                    placeholder="Dani Maison"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                {formErrors?.shopNameError?.length > 0 ? (
                  <span className="text-red-400 font-poppins font-normal text-sm">
                    {formErrors.shopNameError}
                  </span>
                ) : null}
                <div
                  style={{
                    border:
                      formData.shopTag.filter((n) => n).length === 0 &&
                      formErrors.general !== "" &&
                      "2px red solid",
                  }}
                  className="relative flex  items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    <RequiredStar
                      isRed={
                        formData.shopTag.filter((n) => n).length === 0 &&
                        formErrors.general !== ""
                      }
                    />
                    <img
                      src={NameFieldIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <div className="flex mobile:flex-col">
                    <div className="max-w-[60%] grid grid-cols-2 gap-1">
                      {formData.shopTag.map((tag, i) => (
                        <div className="flex gap-1 items-center ">
                          <div className="text-sm mobile:text-[8px]">{tag}</div>
                          <div
                            className="text-red-500 cursor-pointer"
                            onClick={() => {
                              delete formData.shopTag[i];
                              formData.shopTag = formData.shopTag.filter(
                                (n) => n
                              );

                              refresh();
                            }}
                          >
                            x
                          </div>
                        </div>
                      ))}
                    </div>
                    <select
                      ref={shopCatSelectRef}
                      onChange={(e) => {
                        if (formData.shopTag.includes(e.target.value)) {
                        } else {
                          formData.shopTag.push(e.target.value);
                        }
                        refresh();
                        shopCatSelectRef.current.selectedIndex = 0;
                      }}
                      disabled={
                        formData.shopTag.length === 1 &&
                        window.customer.shopCategory === "Culture"
                      }
                      required
                      className="flex-grow bg-inherit text-black1 invalid:text-[#666666] font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none disabled:opacity-40"
                    >
                      <option value="" disabled selected hidden>
                        Tag Attività Commerciale
                      </option>

                      {Cats?.[window.customer.shopCategory]?.map?.((opt) => (
                        <option
                          disabled={opt.heading}
                          className={`${
                            opt.heading ? "font-bold m-2" : "text-black1"
                          } ${opt.heading ? "border" : ""}`}
                          value={opt.text}
                        >
                          {opt.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    border:
                      formErrors.descriptionError !== "" &&
                      formData.description[locale] === "" &&
                      "2px red solid",
                  }}
                  className="relative flex space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg border-[2px] border-lightPurple mb-[16px]"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    <RequiredStar
                      isRed={
                        formErrors.descriptionError !== "" &&
                        formData.description[locale] === ""
                      }
                    />
                    <span className="absolute right-2 top-2">
                      {Math.abs(formData.description[locale].length - 550)}
                    </span>
                    <img
                      src={DescriptionFieldIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <textarea
                    type="text"
                    rows={4}
                    name="description"
                    maxLength={550}
                    value={formData.description[locale].replaceAll(
                      "///n",
                      "\n"
                    )}
                    onChange={handleChange}
                    placeholder="Descrizione"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>

                {formErrors?.descriptionError?.length > 0 ? (
                  <span className="text-red-400 font-poppins font-normal text-sm">
                    {formErrors.descriptionError}
                  </span>
                ) : null}

                <div
                  style={{
                    border:
                      formErrors.general !== "" &&
                      formData.address[locale] === "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-gray4 py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        formData.address[locale] === ""
                      }
                    />
                    <img
                      src={LocationIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Via, Numero, Città"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                    onChange={(e) => {
                      formData.address.it = e.target.value;
                      formData.address.en = e.target.value;
                      refresh();
                    }}
                    name="address"
                    value={formData.address[locale]}
                  />
                </div>
                <div
                  style={{
                    border:
                      Math.round(formData.addressGeopoint._lat) === 0 &&
                      formErrors.general !== "" &&
                      "2px red solid",
                  }}
                  onClick={() => setOpenLocationSelector(true)}
                  className="relative bg-lightPurple md:bg-white rounded-lg font-poppins font-bold md:font-semibold text-center w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-bgColor md:text-customBlue pt-[16px] pb-[13px] hover:cursor-pointer mb-[5px] md:border-[3px] md:border-customBlue"
                >
                  <RequiredStar
                    isRed={
                      Math.round(formData.addressGeopoint._lat) === 0 &&
                      formErrors.general !== ""
                    }
                  />
                  {Math.round(formData.addressGeopoint._lat) !== 0
                    ? "Punto sulla Mappa selezionato"
                    : "Seleziona sulla Mappa"}
                </div>
                <div
                  style={{
                    border:
                      formErrors.general !== "" &&
                      (!formData?.openingHour?.mon.open ||
                        !formData?.openingHour?.tue.open ||
                        !formData?.openingHour?.wed.open ||
                        !formData?.openingHour?.thu.open ||
                        !formData?.openingHour?.fri.open ||
                        !formData?.openingHour?.sat.open ||
                        !formData?.openingHour?.sun.open ||
                        !formData?.openingHour?.mon.close ||
                        !formData?.openingHour?.tue.close ||
                        !formData?.openingHour?.wed.close ||
                        !formData?.openingHour?.thu.close ||
                        !formData?.openingHour?.fri.close ||
                        !formData?.openingHour?.sat.close ||
                        !formData?.openingHour?.sun.close) &&
                      "2px red solid",
                  }}
                  onClick={() => setOpenTimeSelector(true)}
                  className="relative bg-lightPurple md:bg-white rounded-lg font-poppins font-bold md:font-semibold text-center w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-bgColor md:text-customBlue pt-[16px] pb-[13px] hover:cursor-pointer mb-[15px] md:border-[3px] md:border-customBlue"
                >
                  {/* {console.log(formData.general !== "")} */}
                  <RequiredStar
                    isRed={
                      formErrors.general !== "" &&
                      (!formData?.openingHour?.mon.open ||
                        !formData?.openingHour?.tue.open ||
                        !formData?.openingHour?.wed.open ||
                        !formData?.openingHour?.thu.open ||
                        !formData?.openingHour?.fri.open ||
                        !formData?.openingHour?.sat.open ||
                        !formData?.openingHour?.sun.open ||
                        !formData?.openingHour?.mon.close ||
                        !formData?.openingHour?.tue.close ||
                        !formData?.openingHour?.wed.close ||
                        !formData?.openingHour?.thu.close ||
                        !formData?.openingHour?.fri.close ||
                        !formData?.openingHour?.sat.close ||
                        !formData?.openingHour?.sun.close)
                    }
                  />
                  {formData?.openingHour?.mon.open &&
                  formData?.openingHour?.tue.open &&
                  formData?.openingHour?.wed.open &&
                  formData?.openingHour?.thu.open &&
                  formData?.openingHour?.fri.open &&
                  formData?.openingHour?.sat.open &&
                  formData?.openingHour?.sun.open &&
                  formData?.openingHour?.mon.close &&
                  formData?.openingHour?.tue.close &&
                  formData?.openingHour?.wed.close &&
                  formData?.openingHour?.thu.close &&
                  formData?.openingHour?.fri.close &&
                  formData?.openingHour?.sat.close &&
                  formData?.openingHour?.sun.close
                    ? "Orari di apertura selezionati"
                    : "Seleziona orari di apertura"}
                </div>
                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      window.customer.licenseType !== 0 &&
                      window?.customer?.shopCategory !== "Culture" &&
                      formErrors.phoneNumberError !== "" &&
                      (formData.phoneNumber[locale].length <= 0 ||
                        formData.phoneNumber[locale] === "0") &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {window.customer.shopCategory !== "Culture" && (
                      <RequiredStar
                        isRed={
                          window.customer.licenseType !== 0 &&
                          formErrors.phoneNumberError !== "" &&
                          (formData.phoneNumber[locale].length <= 0 ||
                            formData.phoneNumber[locale] === "0")
                        }
                      />
                    )}
                    <img
                      src={PhoneIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="number"
                    name="phoneNumber"
                    // pattern="^[0-9]+$"
                    value={formData.phoneNumber[locale]}
                    onChange={handleChange}
                    disabled={window.customer.licenseType === 0}
                    placeholder="Numero di Telefono"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                {formErrors.phoneNumberError?.length > 0 ? (
                  <span className="text-red-400 font-poppins font-normal text-sm">
                    {formErrors.phoneNumberError}
                  </span>
                ) : null}

                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      formData.mainButtonActionType[locale] === "4" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.firstLinkName[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div>
                    <span className="absolute right-2 top-2">
                      {" "}
                      {Math.abs(formData.firstLinkName[locale].length - 30)}
                    </span>

                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      {window.customer.licenseType === 0 && (
                        <HiLockClosed
                          className="absolute right-2 text-4xl text-customBlue"
                          style={{ opacity: 1, zIndex: 9999 }}
                        />
                      )}
                      {formData.mainButtonActionType[locale] === "4" && (
                        <RequiredStar
                          isRed={
                            formErrors.general !== "" &&
                            // eslint-disable-next-line eqeqeq
                            formData.firstLinkName[locale] == ""
                          }
                        />
                      )}
                      <img
                        src={ApplicationIcon1}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="firstLinkName"
                    value={formData.firstLinkName[locale]}
                    onChange={handleChange}
                    maxLength={30}
                    disabled={window.customer.licenseType === 0}
                    placeholder="Nome del primo link principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      formData.mainButtonActionType[locale] === "4" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.firstLinkDescription[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div>
                    <span className="absolute right-2 top-2">
                      {Math.abs(
                        formData.firstLinkDescription[locale].length - 30
                      )}
                    </span>
                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      {window.customer.licenseType === 0 && (
                        <HiLockClosed
                          className="absolute right-2 text-4xl text-customBlue"
                          style={{ opacity: 1, zIndex: 9999 }}
                        />
                      )}
                      {formData.mainButtonActionType[locale] === "4" && (
                        <RequiredStar
                          isRed={
                            formErrors.general !== "" &&
                            // eslint-disable-next-line eqeqeq
                            formData.firstLinkDescription[locale] == ""
                          }
                        />
                      )}
                      <img
                        src={ApplicationIcon1}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="firstLinkDescription"
                    maxLength={30}
                    value={formData.firstLinkDescription[locale]}
                    disabled={window.customer.licenseType === 0}
                    onChange={handleChange}
                    placeholder="Descrizione del primo link principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      formData.mainButtonActionType[locale] === "4" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.firstLink[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {formData.mainButtonActionType[locale] === "4" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.firstLink[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon1}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    name="firstLink"
                    value={formData.firstLink[locale]}
                    disabled={window.customer.licenseType === 0}
                    onChange={handleChange}
                    placeholder="Primo Link Principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>

                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      formData.mainButtonActionType[locale] === "5" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.secondLinkName[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div>
                    <span className="absolute right-2 top-2">
                      {Math.abs(formData.secondLinkName[locale].length - 30)}
                    </span>
                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      {window.customer.licenseType === 0 && (
                        <HiLockClosed
                          className="absolute right-2 text-4xl text-customBlue"
                          style={{ opacity: 1, zIndex: 9999 }}
                        />
                      )}
                      {formData.mainButtonActionType[locale] === "5" && (
                        <RequiredStar
                          isRed={
                            formErrors.general !== "" &&
                            // eslint-disable-next-line eqeqeq
                            formData.secondLinkName[locale] == ""
                          }
                        />
                      )}
                      <img
                        src={ApplicationIcon2}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="secondLinkName"
                    value={formData.secondLinkName[locale]}
                    onChange={handleChange}
                    disabled={window.customer.licenseType === 0}
                    maxLength={30}
                    placeholder="Nome del secondo link principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,

                    border:
                      formData.mainButtonActionType[locale] === "5" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.secondLinkDescription[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div>
                    <span className="absolute right-2 top-2">
                      {Math.abs(
                        formData.secondLinkDescription[locale].length - 30
                      )}
                    </span>
                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      {window.customer.licenseType === 0 && (
                        <HiLockClosed
                          className="absolute right-2 text-4xl text-customBlue"
                          style={{ opacity: 1, zIndex: 9999 }}
                        />
                      )}
                      {formData.mainButtonActionType[locale] === "5" && (
                        <RequiredStar
                          isRed={
                            formErrors.general !== "" &&
                            // eslint-disable-next-line eqeqeq
                            formData.secondLinkDescription[locale] == ""
                          }
                        />
                      )}
                      <img
                        src={ApplicationIcon2}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="secondLinkDescription"
                    value={formData.secondLinkDescription[locale]}
                    onChange={handleChange}
                    disabled={window.customer.licenseType === 0}
                    maxLength={30}
                    placeholder="Descrizione del secondo link principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                    border:
                      formData.mainButtonActionType[locale] === "5" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.secondLink[locale] == "" &&
                      "2px red solid",
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {formData.mainButtonActionType[locale] === "5" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.secondLink[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={ApplicationIcon2}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    name="secondLink"
                    disabled={window.customer.licenseType === 0}
                    value={formData.secondLink[locale]}
                    onChange={handleChange}
                    placeholder="Secondo Link Principale"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>

                <div
                  style={{
                    border:
                      window.customer.licenseType !== 0 &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.mainButtonText[locale] == "" &&
                      "2px red solid",
                    opacity:
                      window.customer.licenseType === 0
                        ? 0.4
                        : Number(formData.mainButtonActionType[locale]) <= 3 ||
                          Number(formData.mainButtonActionType[locale]) === 6 ||
                          Number(formData.mainButtonActionType[locale]) === 0
                        ? 0.4
                        : 1,
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    <RequiredStar
                      isRed={
                        window.customer.licenseType !== 0 &&
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.mainButtonText[locale] == ""
                      }
                    />
                    <img
                      src={TextIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Testo Pulsante Principale"
                    name="mainButtonText"
                    ref={mainButtonTextField}
                    onChange={handleChange}
                    disabled={
                      window.customer.licenseType === 0 ||
                      mainButtonActionTypeRef?.current?.selectedIndex <= 4 ||
                      mainButtonActionTypeRef?.current?.selectedIndex === 7 ||
                      mainButtonActionTypeRef?.current?.selectedIndex === 0
                    }
                    value={formData.mainButtonText[locale] || ""}
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    marginBottom:
                      formData.mainButtonText[locale] === "Prenota ora"
                        ? 5
                        : 15,
                    border:
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.mainButtonActionType[locale] === "" &&
                      "2px red solid",
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    <RequiredStar
                      isRed={
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.mainButtonActionType[locale] === ""
                      }
                    />
                    <img
                      src={PrincipalFieldIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>
                  <select
                    name="mainButtonActionType"
                    ref={mainButtonActionTypeRef}
                    onChange={changeButtonActionType}
                    disabled={window.customer.licenseType === 0}
                    // value={formData.mainButtonActionType[locale] || ""}
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none invalid:text-[#666666]"
                    required
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      selected={formData.mainButtonActionType[locale] === -1}
                    >
                      Azione Pulsante Principale
                    </option>

                    {mainButtonActionType.map((opt, index) => (
                      <option
                        value={index}
                        selected={
                          formData.mainButtonActionType[locale] === index
                        }
                      >
                        {opt}
                      </option>
                    ))}
                  </select>
                </div>
                {(formData.mainButtonActionType[locale] === 0 ||
                  formData.mainButtonText[locale] === "Prenota ora") && (
                  <div
                    style={{
                      border:
                        formErrors.general !== "" &&
                        // eslint-disable-next-line eqeqeq
                        formData.emailToReceive[locale] == "" &&
                        "2px red solid",
                      opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                    }}
                    className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple"
                  >
                    <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                      {window.customer.licenseType === 0 && (
                        <HiLockClosed
                          className="absolute right-2 text-4xl text-customBlue"
                          style={{ opacity: 1, zIndex: 9999 }}
                        />
                      )}
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.emailToReceive[locale] == ""
                        }
                      />
                      <img
                        src={EmailIcon}
                        alt="icon"
                        className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Email dove ricevere le prenotazioni"
                      name="emailToReceive"
                      onChange={(e) => {
                        formData.emailToReceive.it = e.target.value;
                        formData.emailToReceive.en = e.target.value;
                        refresh();
                      }}
                      disabled={window.customer.licenseType === 0}
                      value={formData?.emailToReceive?.[locale] || ""}
                      className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                    />
                  </div>
                )}

                <div
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                  style={{
                    border:
                      formData.mainButtonText[locale] ===
                        "Seguici su Instagram" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.instagramLink[locale] == "" &&
                      "2px red solid",
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                  }}
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {formData.mainButtonText[locale] ===
                      "Seguici su Instagram" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.instagramLink[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={InstagramIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>

                  <input
                    type="text"
                    name="instagramLink"
                    value={formData.instagramLink[locale]}
                    disabled={window.customer.licenseType === 0}
                    onChange={handleChange}
                    placeholder="Link Instagram"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    border:
                      formData.mainButtonText[locale] ===
                        "Seguici su Facebook" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.facebookLink[locale] == "" &&
                      "2px red solid",
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {formData.mainButtonText[locale] ===
                      "Seguici su Facebook" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.facebookLink[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={FacebookIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>

                  <input
                    type="text"
                    name="facebookLink"
                    value={formData.facebookLink[locale]}
                    disabled={window.customer.licenseType === 0}
                    onChange={handleChange}
                    placeholder="Link Facebook"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>
                <div
                  style={{
                    border:
                      formData.mainButtonText[locale] === "Scrivici una mail" &&
                      formErrors.general !== "" &&
                      // eslint-disable-next-line eqeqeq
                      formData.email[locale] == "" &&
                      "2px red solid",
                    opacity: window.customer.licenseType === 0 ? 0.4 : 1,
                  }}
                  className="relative flex items-center space-x-[10px] bg-lightGray py-[14px] px-[12px] rounded-lg mb-[5px] border-[2px] border-lightPurple"
                >
                  <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                    {window.customer.licenseType === 0 && (
                      <HiLockClosed
                        className="absolute right-2 text-4xl text-customBlue"
                        style={{ opacity: 1, zIndex: 9999 }}
                      />
                    )}
                    {formData.mainButtonText[locale] ===
                      "Scrivici una mail" && (
                      <RequiredStar
                        isRed={
                          formErrors.general !== "" &&
                          // eslint-disable-next-line eqeqeq
                          formData.email[locale] == ""
                        }
                      />
                    )}
                    <img
                      src={EmailIcon}
                      alt="icon"
                      className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                    />
                  </div>

                  <input
                    type="text"
                    name="email"
                    disabled={window.customer.licenseType === 0}
                    value={formData.email[locale]}
                    onChange={handleChange}
                    placeholder="Email"
                    className="flex-grow bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
                  />
                </div>

                {formErrors?.general?.length > 0 ? (
                  <span className="text-red-400 font-poppins font-normal text-sm">
                    {formErrors.general}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="pb-[40px] md:pb-[68px] w-full flex items-center justify-center m-d:m-l-[130px]">
            <button
              className="w-full md:w-[55%] rounded-[14px] font-poppins font-bold text-bgColor text-[16px] leading-[24px] bg-customBlue pt-[16px] pb-[13px] disabled:opacity-40 "
              onClick={handleSubmit}
              disabled={formLoading}
            >
              {formLoading ? <LoadingIcon /> : "Invia Vetrina"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const LocationSelectorModel = ({ setLocation, onClose, DefaultLocation }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scroll(0, 0);
    window.onscroll = () => {
      window.scroll(0, 0);
      console.log("Scroll");
    };

    return () => {
      document.body.style.overflow = "auto";
      window.onscroll = () => {};
    };
  }, []);

  return (
    <>
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-transparent backdrop-blur-[10px] opacity-100"
        onClick={onClose}
      ></div>
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-black backdrop-blur-[10px] opacity-40"
        onClick={onClose}
      ></div>
      <div className="absolute top-1/2 mobile_tablet:-translate-y-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] mobile:w-[350px]  tablet:w-[400px] h-min  bg-white z-30 rounded-xl p-4 pt-8">
        <div
          className="absolute right-4 top-1 mobile_tablet:top-8 text-xl cursor-pointer"
          onClick={onClose}
        >
          ×
        </div>
        <LocationSelector
          onLocationChange={(newLocation) => setLocation(newLocation)}
          onClose={onClose}
          DefaultLocation={DefaultLocation}
        />
      </div>
    </>
  );
};

const englishDays = {
  lun: "mon",
  mar: "tue",
  mer: "wed",
  gio: "thu",
  ven: "fri",
  sab: "sat",
  dom: "sun",
};

const TimeSelectorModel = ({
  setOpeningHours,
  onClose,
  openingHours,
  formData,
  setFormData,
}) => {
  const [selectedDay, setSelectedDay] = useState("lun");

  const [openingHourInputFrom, setOpeningHourInputFrom] = useState("");
  const [openingHourInputTo, setOpeningHourInputTo] = useState("");

  const field1 = useRef(null);
  const field2 = useRef(null);
  const field3 = useRef(null);
  const field4 = useRef(null);

  const [err, setErr] = useState("");

  const [refreshVal, setRefreshVal] = useState(0);

  const refresh = () => setRefreshVal(Math.random());

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  useEffect(() => {
    field1.current.type = "text";
    field1.current.value = formData.openingHour[englishDays[selectedDay]].open;
    field2.current.type = "text";
    field2.current.value = formData.openingHour[englishDays[selectedDay]].close;
    field3.current.type = "text";
    field3.current.value = formData.openingHour[englishDays[selectedDay]].open2;
    field4.current.type = "text";
    field4.current.value =
      formData.openingHour[englishDays[selectedDay]].close2;
  }, [selectedDay]);

  return (
    <>
      {" "}
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-transparent backdrop-blur-[10px] opacity-100"
        onClick={onClose}
      ></div>
      <div
        className="absolute top-0 w-screen h-screen z-20 bg-black backdrop-blur-[10px] opacity-40"
        onClick={onClose}
      ></div>
      <div className="absolute px-8 py-8 mobile_tablet:-translate-y-[40%] min-w-[350px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-max bg-white z-30 rounded-xl p-4 gird place-items-center">
        <div
          className="absolute right-2 top-1 text-xl cursor-pointer mobile:top-4"
          onClick={onClose}
        >
          ×
        </div>

        <div className="flex mobile_tablet:flex-col mobile_tablet:gap-[10px] justify-around gap-[60px] mb-8">
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("lun");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "lun" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "lun" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Lun
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("mar");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "mar" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "mar" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Mar
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("mer");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "mer" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "mer" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Mer
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("gio");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "gio" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "gio" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Gio
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("ven");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "ven" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "ven" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Ven
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("sab");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "sab" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "sab" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Sab
          </div>
          <div
            className={`relative py-2 px-6 border rounded-xl`}
            onClick={() => {
              setSelectedDay("dom");
              refresh();
            }}
            style={{
              boxShadow: "0px 0px 20px rgba(0,0,0,0.25)",
              backgroundColor:
                selectedDay === "dom" ? "rgba(25 103 255)" : "white",
              color: selectedDay === "dom" ? "white" : "black",
            }}
          >
            <RequiredStar />
            Dom
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="relative flex  items-center space-x-[10px] w-[80%] mobile_tablet:w-full bg-gray4 py-[14px] px-[12px] rounded-lg mb-[6px] border-[2px] border-lightPurple">
            <div className="min-w-[25px]">
              <RequiredStar />
              <div className="min-h-[25px] min-w-[25px] md:min-w-[35px] md:min-h-[35px]">
                <RequiredStar />
                <img
                  src={ClockIcon}
                  alt="icon"
                  className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
                />
              </div>
            </div>

            <input
              type="text"
              onFocus={(e) => {
                e.target.type = "time";
                e.target.showPicker();
              }}
              onClick={(e) => {
                e.target.type = "time";
                e.target.showPicker();
              }}
              onTouchStart={(e) => {
                e.target.type = "time";
                e.target.showPicker();
              }}
              value={formData.openingHour[englishDays[selectedDay]].open}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  openingHour: {
                    ...formData.openingHour,
                    [englishDays[selectedDay]]: {
                      ...formData.openingHour[englishDays[selectedDay]],
                      open: e.target.value,
                    },
                  },
                });
              }}
              ref={field1}
              placeholder="Dalle"
              className="w-[49%] bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
            />
            <input
              type="text"
              onFocus={(e) => {
                e.target.type = "time";
              }}
              onClick={(e) => {
                e.target.type = "time";
              }}
              value={formData.openingHour[englishDays[selectedDay]].close}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  openingHour: {
                    ...formData.openingHour,
                    [englishDays[selectedDay]]: {
                      ...formData.openingHour[englishDays[selectedDay]],
                      close: e.target.value,
                    },
                  },
                });
              }}
              ref={field2}
              placeholder="Alle"
              className="w-[49%] bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
            />
          </div>

          <div className="flex justify-between items-center space-x-[10px] w-[80%] mobile_tablet:w-full bg-gray4 py-[14px] px-[12px] rounded-lg mb-[15px] border-[2px] border-lightPurple">
            <div className="min-w-[25px]">
              <RequiredStar />
              <img
                src={ClockIcon}
                alt="icon"
                className="h-[25px] w-[25px] md:w-[35px] md:h-[35px] object-contain"
              />
            </div>
            <input
              type="text"
              onFocus={(e) => {
                e.target.type = "time";
              }}
              onClick={(e) => {
                e.target.type = "time";
              }}
              value={formData.openingHour[englishDays[selectedDay]].open2}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  openingHour: {
                    ...formData.openingHour,
                    [englishDays[selectedDay]]: {
                      ...formData.openingHour[englishDays[selectedDay]],
                      open2: e.target.value,
                    },
                  },
                });
              }}
              ref={field3}
              placeholder="Dalle"
              className="w-[49%] bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
            />
            <input
              type="text"
              onFocus={(e) => {
                e.target.type = "time";
              }}
              onClick={(e) => {
                e.target.type = "time";
              }}
              value={formData.openingHour[englishDays[selectedDay]].close2}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  openingHour: {
                    ...formData.openingHour,
                    [englishDays[selectedDay]]: {
                      ...formData.openingHour[englishDays[selectedDay]],
                      close2: e.target.value,
                    },
                  },
                });
              }}
              ref={field4}
              placeholder="Alle"
              className="w-[49%] bg-inherit text-black1 font-poppins font-normal text-[14px] md:text-[25px] leading-[21px] md:leading-[35px] outline-none"
            />
          </div>
          {err && (
            <span className="text-red-500 mb-[15px] text-left w-[80%] mobile_tablet:w-full">
              {err}
            </span>
          )}
          <div
            onClick={() => {
              setErr("");
              setFormData({
                ...formData,
                openingHour: {
                  ...formData.openingHour,
                  [englishDays[selectedDay]]: {
                    open: "closed",
                    close: "closed",
                    open2: "closed",
                    close2: "closed",
                  },
                },
              });

              field1.current.type = "text";
              field1.current.value = "closed";
              field2.current.type = "text";
              field2.current.value = "closed";
              field3.current.type = "text";
              field3.current.value = "closed";
              field4.current.type = "text";
              field4.current.value = "closed";
              refresh();
            }}
            className="mb-1 bg-lightPurple md:bg-white rounded-lg font-poppins font-bold md:font-semibold text-center w-[80%] mobile_tablet:w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-bgColor md:text-customBlue pt-[16px] pb-[13px] hover:cursor-pointer md:border-[3px] md:border-customBlue"
          >
            Chiuso tutta la giornata
          </div>
          <div
            onClick={() => {
              setErr("");
              setFormData({
                ...formData,
                openingHour: {
                  ...formData.openingHour,
                  [englishDays[selectedDay]]: {
                    open: "opened",
                    close: "opened",
                    open2: "opened",
                    close2: "opened",
                  },
                },
              });

              field1.current.type = "text";
              field1.current.value = "opened";
              field2.current.type = "text";
              field2.current.value = "opened";
              field3.current.type = "text";
              field3.current.value = "opened";
              field4.current.type = "text";
              field4.current.value = "opened";
              refresh();
            }}
            className="mb-1 bg-lightPurple md:bg-white rounded-lg font-poppins font-bold md:font-semibold text-center w-[80%] mobile_tablet:w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-bgColor md:text-customBlue pt-[16px] pb-[13px] hover:cursor-pointer md:border-[3px] md:border-customBlue"
          >
            Aperto tutta la giornata
          </div>
          <div
            onClick={() => {
              onClose();
            }}
            className="bg-customBlue md:bg-customBlue rounded-lg font-poppins font-bold md:font-semibold text-center w-[80%] mobile_tablet:w-full text-[16px] md:text-[25px] leading-[24px] md:leading-[35px] text-white md:text-white pt-[16px] pb-[13px] hover:cursor-pointer md:border-[3px] md:border-customBlue"
          >
            Salva questi orari di apertura
          </div>
        </div>
      </div>
    </>
  );
};

export default Vetrina;
