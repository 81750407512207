import React from "react";
import { Col, Row, Checkbox, Form, Input, Button } from "antd";
import "./style.css";
import Pic from "../../images/pic.png";
import Eyes from "../../images/eye-slash.png";
import { Link, Navigate } from "react-router-dom";
import { db } from "../../firebase";
import { query, where, collection, getDoc, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function SignIn({ setShowSideBar }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [rememberMe, setRememberMe] = React.useState(false);

  const navigate = useNavigate();

  const [err, setErr] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const onSignIn = async () => {
    setLoading(true);

    const q = query(
      collection(db, "Customers"),
      where("email", "==", email.toLowerCase())
    );
    const snapshot = await getDocs(q);

    if (snapshot.docs.length === 0) {
      setErr("Credenziali errate");
    } else {
      const user = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))[0];
      if (user.password !== password) setErr("Password is incorrect!");
      else {
        window.customerId = user.id;
        window.customer = user;

        if (rememberMe) {
          localStorage.setItem("customerId", window.customerId);
          localStorage.setItem("customer", JSON.stringify(window.customer));
        }

        sessionStorage.setItem("customerId", window.customerId);
        sessionStorage.setItem("customer", JSON.stringify(window.customer));

        setShowSideBar(true);
        navigate("/dashboard");
      }
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  function chkInternetStatus() {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    err &&
      setTimeout(() => {
        setErr("");
      }, 6000);
  }, [err]);

  // if (window.customerId) window.location.replace("/dashboard");

  return (
    <div className="mainsigin min-h-screen w-full desktop:px-[240px] laptop:px-[0px]">
      <Row
        justify="space-evenly"
        className="flex tablet:flex-col-reverse mobile:flex-col-reverse"
      >
        <Col lg={10} md={20} xs={24}>
          <div className="centerSignIn33">
            <div>
              <div className="textdivti33 tablet:mt-[-50px] mobile:mt-[-50px]">
                <h1 className="textti77">
                  Accedi su <a className="textti23577"> Info</a>Ischia
                  <a className="textti23477">Pro</a>
                </h1>
              </div>
              <p className="textti2177">
                Il gestionale di InfoIschia a portata di mano
              </p>
            </div>
            <img className="img-fluid imgcom66" src={Pic} alt="" />
          </div>
        </Col>
        <Col lg={10} md={20} xs={24}>
          <div className="centerSignIn">
            <div className="centerSignInd22">
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onSignIn}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{ position: "relative" }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    className="inputin99"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="inputin99"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </Form>
              {err && (
                <p
                  className="w-full pl-3"
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: 14,
                  }}
                >
                  {err}
                </p>
              )}
              <div className="checkdiv">
                <Checkbox
                  className="checktext"
                  onChange={() => setRememberMe(!rememberMe)}
                >
                  Ricordami
                </Checkbox>
                <p className="checktext">
                  <Link to="/invalid">Hai un problema?</Link>
                </p>
              </div>
              <Form.Item>
                <Button
                  className="btninvia99 bg-customBlue"
                  type="primary"
                  htmlType="submit"
                  disabled={!email || !password || loading}
                  onClick={() => {
                    if (chkInternetStatus()) {
                      onSignIn();
                    } else {
                      setErr("Nessuna connessione internet");
                    }
                  }}
                >
                  Login
                </Button>
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SignIn;
