import React from "react";
import { Input } from "antd";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  Timestamp,
  GeoPoint,
  getDocs,
  doc,
  query,
  where,
} from "firebase/firestore";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.css";
import useQuery from "../useQuery";

const { TextArea } = Input;

function Component3({ setShowSideBar }) {
  const [referringName, setReferringName] = React.useState("");
  const [referentSurname, setReferentSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [venueName, setVenueName] = React.useState("");
  const [categoria, setCategoria] = React.useState("");
  const [linkToSocialMedia, setLinkToSocialMedia] = React.useState("");
  const [shopCategorySelected, setShopCategorySelected] = React.useState("");

  const [isFormValid, setIsFormValid] = React.useState(false);
  const navigate = useNavigate();
  const urlQuery = useQuery();

  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkFormValidity = () => {
    if (
      referringName &&
      referentSurname &&
      email &&
      phone &&
      venueName &&
      categoria &&
      linkToSocialMedia
    )
      return setIsFormValid(true);
    if (
      !referringName ||
      !referentSurname ||
      !email ||
      !phone ||
      !venueName ||
      !categoria ||
      !linkToSocialMedia
    )
      return setIsFormValid(false);
  };

  const onSubmitForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    function randomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
    const q = query(
      collection(db, "Customers"),
      where("email", "==", email.toLowerCase())
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length !== 0) {
      setLoading(false);
      return setErr("Email già connessa a un altro account");
    }

    const docRef = await addDoc(collection(db, "Customers"), {
      email: email.toLowerCase(),
      password: randomString(9),
      firstName: referringName,
      lastName: referentSurname,
      phoneNumber: phone,
      shopName: venueName,
      shopCategory: categoria,
      shopCategorySelected: shopCategorySelected,
      shopId: "",
      eventNum: 0,
      eventRequest: -1,
      eventSelectedDocumentId: "",
      subscribedTimestamp: new Date(),
      licenseType: -1,
      licenseTimestamp: new Date(
        new Date(new Date().getTime()).setDate(new Date().getDate() - 1)
      ),
      windowRequest: -1,
      freeEvent: {
        firstMonth: true,
        secondMonth: true,
        thirdMonth: true,
        fourthMonth: true,
        fifthMonth: true,
        sixthMonth: true,
        seventhMonth: true,
        eighthMonth: true,
        ninthMonth: true,
        tenthMonth: true,
        eleventhMonth: true,
        twelvethMonth: true,
      },
      windowRequestMap: {
        address: { en: "", it: "" },
        addressGeopoint: new GeoPoint(0, 0),
        description: { en: "", it: "" },
        email: { en: "", it: "" },
        facebookLink: { en: "", it: "" },
        firstLink: { en: "", it: "" },
        firstLinkDescription: { en: "", it: "" },
        instagramLink: { en: "", it: "" },
        mainButtonActionType: { en: -1, it: -1 },
        mainButtonText: { en: "", it: "" },
        openingHour: {
          mon: { open: "", close: "", open2: "", close2: "" },
          tue: { open: "", close: "", open2: "", close2: "" },
          wed: { close: "", open: "", close2: "", open2: "" },
          thu: { close: "", open: "", close2: "", open2: "" },
          fri: { open: "", close: "", open2: "", close2: "" },
          sat: { close: "", open: "", close2: "", open2: "" },
          sun: { close: "", open: "", close2: "", open2: "" },
        },
        phoneNumber: { en: "", it: "" },
        firstLinkName: { en: "", it: "" },
        secondLink: { en: "", it: "" },
        secondLinkName: { en: "", it: "" },
        secondLinkDescription: { en: "", it: "" },
        shopName: venueName,
        shopTag: [],
        imageUrls: { en: [], it: [] },
        lastSentTimestamp: new Date(),
      },
      eventRequestMap: {
        eventDescription: { en: "", it: "" },
        facebookLink: { en: "", it: "" },
        firstLink: { en: "", it: "" },
        firstLinkName: { en: "", it: "" },
        firstLinkDescription: { en: "", it: "" },
        instagramLink: { en: "", it: "" },
        mainButtonActionType: { en: 0, it: 0 },
        mainButtonText: { en: "", it: "" },
        phoneNumber: { en: "", it: "" },
        secondLink: { en: "", it: "" },
        secondLinkName: { en: "", it: "" },
        secondLinkDescription: { en: "", it: "" },
        eventName: { en: "", it: "" },
        email: { en: "", it: "" },
        eventStartTimestamp: new Date(),
        eventEndTimestamp: new Date(),
        shopTag: "",
        imageUrls: { en: [], it: [] },
      },
    });
    await addDoc(collection(db, "Customers", docRef.id, "recentActivities"), {
      timestamp: new Date(),
      text: `Iscritto come ${venueName}, il referente è ${referringName} ${referentSurname}`,
    });

    window.customerId = docRef.id;
    localStorage.setItem("customerId", window.customerId);
    // localStorage.setItem("customer", JSON.stringify(window.customer));
    // window.customer = user;
    setShowSideBar(true);
    navigate("/dashboard");
    setLoading(false);
  };
  React.useEffect(() => {
    checkFormValidity();
  }, [
    referringName,
    referentSurname,
    email,
    phone,
    venueName,
    categoria,
    linkToSocialMedia,
    checkFormValidity,
  ]);

  return (
    <form id="form" className="mb-5" onSubmit={onSubmitForm}>
      <div className="head1">
        <h1 className="headtext2 text-center">
          Iscrivi ora il tuo esercizio commerciale
        </h1>
        <h2 className="pp text-3xl">
          Per passare al pacchetto <a className="pp2">Pro</a> devi essere
          iscritto
        </h2>
      </div>
      <br />
      <div className="flex flex-col justify-center items-center mobile_tablet:px-8">
        <div className="flex flex-col max-w-[800px] justify-center items-center">
          <div className="flex gap-[50px] tablet:gap-[30px] mobile:gap-[15px]">
            <input
              type="text"
              placeholder="Nome Referente"
              className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
              name="referringName"
              onChange={(e) => setReferringName(e.target.value.trim())}
            />
            <input
              type="text"
              placeholder="Cognome Referente"
              className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
              name="referentSurname"
              onChange={(e) => setReferentSurname(e.target.value.trim())}
            />
          </div>
          <input
            placeholder="Email"
            name="email"
            onChange={(e) => setEmail(e.target.value.trim())}
            className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
          />
          <input
            placeholder="Numero di telefono"
            type="number"
            name="phone-no"
            onChange={(e) => setPhone(e.target.value)}
            className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
          />
          <input
            placeholder="Nome del Locale"
            name="venueName"
            onChange={(e) => setVenueName(e.target.value.trim())}
            className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
          />
          <select
            name="Categoria"
            onChange={(e) => {
              setShopCategorySelected(
                e.target[e.target.selectedIndex].innerText
              );
              setCategoria(e.target.value);
            }}
            className={`bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full invalid:text-[#666666]`}
            required
          >
            <option
              value=""
              disabled
              selected
              hidden
              // className="text-[rgb(117, 117, 117)] opacity-40"
            >
              Categoria dell'attività commerciale
            </option>
            {urlQuery.get("culture") !== null && (
              <option value="Culture">Punto Culturale</option>
            )}
            <option value={"Shopping"}>Abbigliamento</option>
            <option value={"Shopping"}>Articoli sportivi</option>
            <option value={"Restaurants"}>Bar</option>
            <option value={"Hotels"}>B&B</option>
            <option value={"Shopping"}>Bambini</option>
            <option value={"Shopping"}>Calzature e Accessori</option>
            <option value={"Hotels"}>Case Vacanze</option>
            <option value={"Restaurants"}>Discoteche</option>
            <option value={"Shopping"}>Elettronica</option>
            <option value={"Shopping"}>Gioiellerie</option>
            <option value={"Hotels"}>Hotel</option>
            <option value={"Shopping"}>Intimo e Mare</option>
            <option value={"Shopping"}>Librerie</option>
            <option value={"Shopping"}>Negozio di Shopping</option>
            <option value={"Restaurants"}>Osterie</option>
            <option value={"Wellbeing"}>Benessere</option>
            <option value={"Shopping"}>Piante e Fiori</option>
            <option value={"Restaurants"}>Pizzerie</option>
            <option value={"Wellbeing"}>Palestre</option>
            <option value={"Wellbeing"}>Parrucchieri</option>
            <option value={"Shopping"}>Profumerie</option>
            <option value={"Restaurants"}>Pub</option>
            <option value={"Restaurants"}>Ristoranti</option>
            <option value={"Hotels"}>Residence</option>
            <option value={"Beach"}>Stabilimenti balneari</option>
            <option value={"Wellbeing"}>Tatuaggi</option>
            <option value={"Rent"}>Uffici Noleggio</option>
            <option value={"Shopping"}>Ottica e Foto</option>
            <option value={"Shopping"}>Prodotti Locali e Souvenir</option>
            <option value={"Rent"}>Servizi generali</option>
            <option value={"Restaurants"}>Food&Drink</option>
            <option value={"Shopping"}>Shopping</option>
            <option value={"Hotels"}>Alloggi</option>
            <option value={"Wellbeing"}>Benessere</option>
          </select>
          <TextArea
            rows={4}
            placeholder="Link al sito web oppure a profili social del locale"
            name="linkToSocialMedia"
            onChange={(e) => setLinkToSocialMedia(e.target.value)}
            className="bg-[#efedff] p-[3rem] rounded-[1rem] mt-[1.5rem] border-none text-[2rem] font-[500] w-full"
          />
          {err && (
            <p className=" inputin4456 bg-transparent text-red-500 p-0 text-3xl">
              {err}
            </p>
          )}
          <button
            className=" p-[3rem] rounded-[1rem] text-[3rem] text-white font-[600] w-full mt-[2rem] disabled:opacity-40 bg-customBlue mb-8"
            type="submit"
            disabled={!isFormValid || loading}
          >
            Iscriviti
          </button>
          <>
            <p
              className="textacce mt-4 mb-12 hidden mobile:block tablet:block cursor-pointer"
              onClick={() => {
                window.scroll(0, 0);
                navigate("/signIn");
              }}
            >
              Accedi a <a className="textacce1">Info</a>Ischia
              <a className="textacce1">Pro</a>
            </p>
          </>
        </div>
      </div>
    </form>
  );
}

export default Component3;
