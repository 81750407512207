import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import EventInfoCard from "./EventInfoCard/EventInfoCard";
import TotalInfo from "./TotalInfo/TotalInfo";

const InfoCard = ({ eventPublication }) => {
  const [licenseDate, setLicenseDate] = useState(0);
  // const [eventPublication, setEventPublication] = useState(0);
  const [eventName, setEventName] = useState({ en: "", it: "" });
  const navigate = useNavigate();

  useEffect(() => {
    if (window.customerId) {
      getDoc(doc(db, "Customers", window.customerId))
        .then((document) => {
          if (document.data().licenseTimestamp) {
            // serverTimestamp()
            if (
              new Date(document.data().licenseTimestamp.toDate()).getTime() >
              new Date().getTime()
            ) {
              setLicenseDate(
                parseInt(
                  (new Date(
                    document.data().licenseTimestamp.toDate()
                  ).getTime() -
                    new Date().getTime()) /
                    (1000 * 3600 * 24)
                )
              );
            } else {
              setLicenseDate(0);
            }

            if (window.customer.eventSelectedDocumentId) {
              getDoc(
                doc(
                  db,
                  "Customers",
                  window.customerId,
                  "events",
                  window.customer.eventSelectedDocumentId
                )
              )
                .then((newDoc) => {
                  setEventName(newDoc.data().eventName);
                })
                .catch((err) => console.error(err));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [window.customerId]);

  return (
    <div className="bg-[#A3A2D8] rounded-[24px] md:rounded-[28px] flex flex-col items-center px-[22px] md:px-[58px]">
      {window.customer.licenseType === 1 && (
        <>
          <div className="flex items-center mt-[17px] space-x-[14px]">
            <EventInfoCard
              eventName="Eventi da pubblicare"
              number={eventPublication}
            />
            <EventInfoCard
              eventName="Licenza in scadenza"
              number={licenseDate}
              useSubtext
              subText="Giorni Mancanti"
            />
          </div>

          <span className="font-poppins font-bold text-[13px] md:text-[35px] leading-[19.5px] md:leading-[52px] text-lightGray mt-[15px] mb-[6px] md:mt-[34px] md:mb-[10px]">
            {eventName.it}
          </span>

          <div className="mb-[18px] w-full md:mb-[45px]">
            <TotalInfo />
          </div>
        </>
      )}
      {(window.customer.licenseType === 0 ||
        window.customer.licenseType === -1) && (
        <>
          <div className="flex items-center mt-[17px] space-x-[14px]">
            <EventInfoCard eventName="Eventi da pubblicare" number={0} />
          </div>
          <span className="font-poppins font-bold text-[13px] md:text-[35px] leading-[19.5px] md:leading-[52px] text-lightGray mt-[15px] mb-[6px] md:mt-[34px] md:mb-[10px]"></span>

          <div className="mb-[18px] w-full md:mb-[45px]">
            <div className="bg-white px-8 py-4 mobile:px-4 rounded-2xl md:rounded-[34px] grid place-items-center">
              <div className="flex flex-col items-center justify-center w-full">
                <img src={"/favicon.svg"} alt="logo" width="25" />
                <br />
                <h1 className="text-xl mobile:text-lg text-customBlue font-bold text-center">
                  Sblocca tutte le funzionalità di{" "}
                  <span className="text-customPink">Info</span>Ischia
                  <span className="text-customPink  text-sm">Pro</span>
                </h1>
                <p className="text-center mobile:text-sm text-gray-400">
                  Rendi la tua vetrina su InfoIschia unica! Tieni sotto
                  controllo tutto ciò che succede grazie a{" "}
                  <span className="text-customPink">Info</span>
                  <span className="text-customBlue">Ischia</span>
                  <span className="text-customPink text-sm mobile:text-[7px]">
                    Pro
                  </span>
                </p>
                <button
                  onClick={() => navigate("/payment")}
                  disabled={window.customer.licenseType === -1}
                  className="bg-customBlue text-white py-4 rounded-2xl font-bold text-xl mx-4 w-full my-4 disabled:opacity-40"
                >
                  Iscriviti a InfoIschia Pro
                </button>
                <h1
                  className={
                    "font-bold text-customBlue text-center mobile:text-[12px]"
                  }
                  style={{
                    display:
                      window.customer.licenseType === 0 ? "none" : "block",
                  }}
                >
                  L’iscrizione è in attesa di approvazione
                </h1>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InfoCard;
